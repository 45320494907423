import * as React from 'react';
import { useEffect, useState } from "react";
import client from "../../../../api/client";
import { getAdminAssessmentQuestion } from "../../../../api/sdk.gen";
import { getCellValue } from '../../../../helpers/assessment'

const DetailsTab = ({ details }) => {

  if (!details) return <div>Loading...</div>;

  const columns = [
    {
      key: "id",
      name: "ID",
      renderer: (item, value) => (
        <a
          href={`/versions/${item.editing_document.scheduled_review_version_id}/edit`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "case_summary_identifier",
      name: "ITEM LABEL",
      renderer: (item, value) => (
        <a
          href={`/versions/${item.editing_document.scheduled_review_version_id}/edit`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "course_name",
      name: "COURSE",
    },
    {
      key: "document.title",
      name: "CASE",
      renderer: (item, value) => (
        <a
          href={`/documents/${item.document_id}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "current_use",
      name: "QUESTION USE",
    },
    {
      key: "efficiency_breakpoints_seconds",
      name: "SCORABLE?",
    },
    {
      key: "content.vignette",
      name: "VIGNETTE",
    },
    {
      key: "content.stem",
      name: "STEM",
    },
    {
      key: "content.images",
      name: "IMAGES",
    },
    {
      key: "content.correct_answer_index",
      name: "CORRECT ANSWER",
    },
    {
      key: "choice_a",
      name: "CHOICE A",
    },
    {
      key: "choice_b",
      name: "CHOICE B",
    },
    {
      key: "choice_c",
      name: "CHOICE C",
    },
    {
      key: "choice_d",
      name: "CHOICE D",
    },
    {
      key: "choice_e",
      name: "CHOICE E",
    },
    {
      key: "difficulty",
      name: "DIFFICULTY",
    },
    {
      key: "discrimination",
      name: "DISCRIMINATION",
    },
    {
      key: "status",
      name: "VALIDITY STATUS",
    },
    {
      key: "learning_objective_reusable_object.learning_objective_name",
      name: "LEARNING OBJECTIVES",
    },
    {
      key: "learning_objective_reusable_object.learning_objective_id",
      name: "LO ID",
      renderer: (item, value) => (
        <a
          href={`/admin/assessment/learning_objectives?id=${value}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "learning_objective_reusable_object.reusable_object_name",
      name: "TEACHING POINTS",
    },
    {
      key: "learning_objective_reusable_object.reusable_object_id",
      name: "TP ID",
      renderer: (item, value) => (
        <a
          href={`/admin/assessment/reusable_objects?id=${value}`}
          target='_blank'
        >
          {value}
        </a>
      )
    },
    {
      key: "question_set_ids",
      name: "SETS",
      renderer: (item, value) => (
        <div>
          <a
            href={`/admin/assessment/question_sets?question_id=${item.id}`}
            target='_blank'
            rel="noopener noreferrer"
          >
            {item.question_sets.length} items
          </a>

          {item.question_sets.map((item) => (
            <div key={item.id}>
              <a
                href={`/admin/assessment/question_sets?id=${item.id}`}
                target='_blank'
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </div>
          ))}
        </div>
      )
    },
    {
      key: "content.answer_explanation",
      name: "ANSWER EXPLANATION",
    },
    {
      key: "system",
      name: "SYSTEM",
    },
    {
      key: "clinical_focus",
      name: "CLINICAL FOCUS",
    },
    {
      key: "clinical_location",
      name: "CLINICAL LOCATION",
    },
    {
      key: "patient_age",
      name: "PATIENT AGE",
    },
    {
      key: "final_diagnosis",
      name: "FINAL DIAGNOSIS",
    },
    {
      key: "clinical_disciplines",
      name: "CLINICAL DISCIPLINE",
    },
    {
      key: "clinical_excellence",
      name: "CLINICAL EXCELLENCE",
    },
    {
      key: "parent_id",
      name: "PARENT ID",
    },
    {
      key: "child_ids",
      name: "CHILD IDs",
    },
    {
      key: "created_at",
      name: "DATE CREATED",
    },
    {
      key: "updated_at",
      name: "DATE MODIFIED",
    },
  ];

  return (
    <dl className="details-list">
      {columns.map(({ key, name, renderer }) => {
        const val = getCellValue(details, key, false)

        return (
          <React.Fragment key={key}>
            <dt className="details-term">{name}</dt>
            <dd className="details-desc">
              { renderer
                ? renderer(details, val)
                : <div dangerouslySetInnerHTML={{ __html: val }} /> }
            </dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
};

export default DetailsTab;
