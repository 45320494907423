import * as React from 'react';
import { useEffect, useState } from "react";
import * as classNames from "classnames";

import client from "../../../api/client";
import { getAdminVersions } from "../../../api/sdk.gen";

const VersionsList = ({ itemId, itemType, itemData }) => {
  client;

  const [versions, setVersions] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [comparisonKeys, setComparisonKeys] = useState<string[]>([]);
  const [hideCreateEvent, setHideCreateEvent] = useState(true);
  const [hideUnmodifiedColumns, setHideUnmodifiedColumns] = useState(false);

  useEffect(() => {
    const fetchVersions = async () => {
      const resp = await getAdminVersions({
        query: { item_id: itemId, item_type: itemType, per: 1000 },
      });

      setVersions(resp.data.data);
    };
    fetchVersions();
  }, [itemId]);

  useEffect(() => {
    const res = hideCreateEvent
      ? versions.filter((version) => version.event !== "create")
      : versions;
    setFilteredVersions(res)
  }, [versions, hideCreateEvent]);

  useEffect(() => {
    generateComparisonKeys();
  }, [filteredVersions, hideUnmodifiedColumns]);

  const extractKeys = (obj: Record<string, any>, prefix = ""): string[] => {
    return Object.keys(obj).reduce<string[]>((keys, key) => {
      const fullPath = prefix ? `${prefix}.${key}` : key;

      if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        keys.push(...extractKeys(obj[key], fullPath));
      } else {
        keys.push(fullPath);
      }

      return keys;
    }, []);
  };

  const generateComparisonKeys = () => {
    const keysSet = new Set<string>();

    // Loop through all versions to gather the keys
    filteredVersions.forEach((version) => {
      if (version.reified_object) {
        const keys = extractKeys(version.reified_object);
        keys.forEach((key) => keysSet.add(key));
      }
    });

    // Only include modified keys if hideUnmodifiedColumns is true
    if (hideUnmodifiedColumns) {
      const modifiedKeys = Array.from(keysSet).filter((key) => {
        for (let i = 0; i < filteredVersions.length; i++) {
          const currentValue = getValueByPath(filteredVersions[i].reified_object || {}, key);
          const previousObject = i === 0 ? itemData : filteredVersions[i - 1]?.reified_object;
          const previousValue = getValueByPath(previousObject || {}, key);
          
          // Skip "create" event versions when filtering
          if (hideCreateEvent && versions[i].event === "create") {
            continue;
          }

          if ((previousValue || currentValue) && JSON.stringify(currentValue) !== JSON.stringify(previousValue)) {
            return true;
          }
        }
        return false;
      });
      setComparisonKeys(modifiedKeys);
    } else {
      // If hideUnmodifiedColumns is false, include all keys
      setComparisonKeys(Array.from(keysSet));
    }
  };

  const getValueByPath = (obj: Record<string, any>, path: string) => {
    return path
      .split(".")
      .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
  };

  const renderTableHead = () => {
    return (
      <tr>
        <th>Version</th>
        <th>Event</th>
        <th>Editor</th>
        <th>Created At</th>
        {comparisonKeys.map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    );
  };

  const renderCurrentState = () => {
    return (
      <tr>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>Current state</td>
        {comparisonKeys.map((key) => {
          const currentValue = getValueByPath(itemData || {}, key);
          
          return (
            <td key={key}>
              {currentValue}
            </td>
          );
        })}
      </tr>
    );
  };

  const renderTableRows = () => {
    if (filteredVersions.length === 0) {
      return (
        <tr>
          <td colSpan={4} style={{ textAlign: "center" }}>
            There are no records
          </td>
        </tr>
      );
    }

    return filteredVersions.map((version, index) => {
      const prevVersion = index === 0 ? { reified_object: itemData } : filteredVersions[index - 1];

      return (
        <tr key={version.id}>
          <td>{version.id}</td>
          <td>{version.event}</td>
          <td>{version.whodunnit || "Unknown"}</td>
          <td>{new Date(version.created_at).toLocaleString()}</td>
          {comparisonKeys.map((key) => {
            const currentValue = getValueByPath(version.reified_object || {}, key);
            const previousValue = getValueByPath(prevVersion?.reified_object || {}, key);

            const isLost = (previousValue || currentValue) && JSON.stringify(previousValue) !== JSON.stringify(currentValue);

            return (
              <td
                key={key}
                style={{
                  backgroundColor: isLost ? "#f8d7da" : undefined,
                }}
              >
                {currentValue}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="table-container">
      <div className="filters">
        <label>
          <input
            type="checkbox"
            checked={hideCreateEvent}
            onChange={(e) => setHideCreateEvent(e.target.checked)}
          />
          Hide Create Event
        </label>
        <label style={{marginLeft: '10px'}}>
          <input
            type="checkbox"
            checked={hideUnmodifiedColumns}
            onChange={(e) => setHideUnmodifiedColumns(e.target.checked)}
          />
          Hide Unmodified Columns
        </label>
      </div>
      <table className="table-with-fixed-columns">
        <thead>{renderTableHead()}</thead>
        <tbody>
          {renderCurrentState()}
          {renderTableRows()}
        </tbody>
      </table>
    </div>
  );
};

export default VersionsList;
