import * as React from "react";
import { isEmpty, trim, last } from "lodash";

import {
  findNodesLineages,
} from "../../../helpers/Doc/Functions/base";

import { DIAGNOSES, FINDINGS, NOTES } from "../../../reducers/ui/reasoningTool";
import ReasoningToolDiagnosisSummary from "./DiagnosisSummary";
import ReasoningToolNoteSummary from "./NoteSummary";
import ReasoningToolFindingSummary from "./FindingSummary";
import ReasoningToolPanel from "./Panel";
import analyticsModule from "../../../../application/analytics.js";

const titles = {
  [DIAGNOSES]: "Diagnoses",
  [FINDINGS]: "Findings",
  [NOTES]: "Notes",
};

const infoBoxes = {
  [NOTES]: "Click to add a new note below.  These notes are only visible to you.",
};

const panelComponents = {
  [DIAGNOSES]: ReasoningToolDiagnosisSummary,
  [FINDINGS]: ReasoningToolFindingSummary,
  [NOTES]: ReasoningToolNoteSummary,
};

function attachNodesToNotes(notes: Array<DocNote>, doc) {
  const noteNodesUids = notes.map((note) => note.notedNodeUid).filter((uid) => uid);
  const noteNodeLineages = findNodesLineages(doc, (node) => node.type && noteNodesUids.includes(node.uid));
  const noteNodeLineagesLookup = Object.fromEntries(noteNodeLineages.map((noteNodeLineage: Array<any>) => [last(noteNodeLineage).uid, noteNodeLineage])); // TODO: Remove Array<any>

  return notes.map((note) => { return {...note, noteNodeLineage: noteNodeLineagesLookup[note.notedNodeUid]}; });
}

interface ReasoningToolTextListPanelProps {
  wide: boolean;
  documentContext: DocumentContext;
  displayContext: DisplayContext;
  userContext: UserContext;
  reasoningToolContext: ReasoningToolContext;
  leadingDiagnosis: string;
  diagnoses: Array<DocDiagnosis>;
  findings: Array<DocFinding>;
  notes: Array<DocNote>;
};

export default function ReasoningToolTextListPanel(props: ReasoningToolTextListPanelProps) {
  const {
    wide,
    documentContext,
    displayContext,
    userContext,
    reasoningToolContext,
    leadingDiagnosis,
    diagnoses = [],
    findings = [],
    notes = [],
  } = props;

  const {
    activePanel,
    togglePanelDeleting,
    panelNodeEditStart,
    panelNodeEditEnd,
    isPanelDeleting,
    activeEditNodeUid,
  } = reasoningToolContext;


  let items = [];
  let panelData : {
    addNode: (uid: string, data?: Record<string, any>) => void;
    updateNode: (uid: string, data: Record<string, any>) => void;
    deleteNode: (uid: string) => void;
    setIsDangerousDiagnosis?: (uid: string, value: boolean) => void;
    setIsLeadingDiagnosis?: (uid: string) => void;
    userContext?: UserContext;
  }
  switch (activePanel) {
    case DIAGNOSES:
      panelData = {
        addNode: userContext.addDiagnosis,
        updateNode: userContext.updateDiagnosis,
        deleteNode: userContext.deleteDiagnosis,
        setIsDangerousDiagnosis: userContext.setIsDangerousDiagnosis,
        setIsLeadingDiagnosis: userContext.setIsLeadingDiagnosis,
      };

      items = diagnoses;
      break;
    case FINDINGS:
      panelData = {
        addNode: userContext.addFinding,
        updateNode: userContext.updateFinding,
        deleteNode: userContext.deleteFinding,
      };

      items = findings;
      break;
    case NOTES:
      panelData = {
        addNode: userContext.addNote,
        updateNode: userContext.updateNote,
        deleteNode: userContext.deleteNote,
        userContext: userContext,
      };

      items = attachNodesToNotes(notes, props);
      break;
    default:
      return null;
  }

  const PanelComponent = panelComponents[activePanel];
  const renderedItems = items.map((item) => {
    const startNodeEdit = () => panelNodeEditStart(item.uid);

    const endNodeEdit = (e: React.FocusEvent<HTMLInputElement>) => {
      if (isEmpty(trim(e.target.value))) {
        panelData.deleteNode(item.uid);
        displayContext.setFocusedBookmarkUid(null);
      } else {
        panelData.updateNode(item.uid, {content: e.target.value}),
        panelNodeEditEnd(item.uid);
        trackEvent(item.type);
      }
    };

    return (
      <PanelComponent
        {...item}
        key={item.uid}
        isEditing={!!activeEditNodeUid && item.uid === activeEditNodeUid}
        startNodeEdit={startNodeEdit}
        endNodeEdit={endNodeEdit}
        isLeadingDiagnosis={!!leadingDiagnosis && item.uid === leadingDiagnosis}
        documentContext={documentContext}
        displayContext={displayContext}
        {...panelData}
      />
    );
  });

  const trackEvent = (type) => {
    const caseName = documentContext.title

    switch(type) {
      case 'diagnosis':
        analyticsModule.track('add_a_diagnosis', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case 'finding':
        analyticsModule.track('add_a_finding', { page: window.location.href, source: 'Case', case: caseName})
        break;
      case 'note':
        analyticsModule.track('use_notes_feature_on_the_page', { page: window.location.href, source: 'Case', case: caseName})
        break;
    }
  }

  return (
    <ReasoningToolPanel
      title={titles[activePanel]}
      infoBox={infoBoxes[activePanel]}
      isDeleting={isPanelDeleting}
      toggleDeleting={togglePanelDeleting}
      isAddable
      wide={wide}
      panelNodeEditStart={panelNodeEditStart}
      displayContext={displayContext}
      {...panelData}
    >
      {renderedItems}
    </ReasoningToolPanel>
  );
}
