import { formatDate } from './dates'
import { truncateWithTooltip, formatLabel } from './utils'

export const answerChoices = ["A", "B", "C", "D", "E"];

export const formatCellValue = (value, truncate) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value.join(", ") : "-";
  }
  if (value == null || value === "") return "-";

  const stringValue = String(value);
  return truncate && stringValue.length > 200 ? truncateWithTooltip(stringValue) : stringValue;
};

export const resolveNestedValue = (obj, key) =>
  key.split(".").reduce((acc, field) => (acc && acc[field] ? acc[field] : null), obj);

export const getSpecialCellValue = (question, key) => {
  if (key === "select") return null;
  if (key === "efficiency_breakpoints_seconds") return (Array.isArray(question[key]) && question[key].length > 0) ? 'Yes' : 'No';
  if (key === "updated_at" || key === "created_at") return formatDate(question[key]);
  if (key === "status") return formatLabel(question[key]);
  if (key === "course_name") return question["case_summary_identifier"].replace(/\d{2}-\d{2}$/, "");

  if (key === "content.images") {
    const images = question.content?.images || [];
    return !Array.isArray(images) || images.length === 0
      ? "-"
      : images
          .map(
            (img, index) =>
              `<a href="${img}" target="_blank" style="--background: url(${img})" class="qb-thumbnail">image ${index + 1}</a>`
          )
          .join("<br />");
  }

  if (key === "content.correct_answer_index") {
    return answerChoices[question.content?.correct_answer_index];
  }

  if (key.startsWith("choice_")) {
    const index = answerChoices.indexOf(key.split("_")[1].toUpperCase());
    return question.content?.choices?.[index] || "-";
  }

  return null;
};

// Main function for getting cell value
export const getCellValue = (question, key, truncate = true) => {
  const specialValue = getSpecialCellValue(question, key);
  if (specialValue !== null) return specialValue;

  const value = resolveNestedValue(question, key);
  return formatCellValue(value, truncate);
};
