// src/components/ClcBar.js
import React from "react";

const ClcBar = ({ data }) => {
  const getClcStyle = (percentage) => {
    if (percentage <= 0) {
      return { display: "none" };
    }
    return { width: `${percentage}%` };
  };

  return (
    <div className="row graphSectionRow clc-bar">
      <div
        className="overallPerformancesBar excel"
        style={getClcStyle(data.cognitive_competence_score_excel)}
      ></div>
      <div
        className="overallPerformancesBar competent"
        style={getClcStyle(data.cognitive_competence_score_competent)}
      ></div>
      <div
        className="overallPerformancesBar unsure"
        style={getClcStyle(data.cognitive_competence_score_unsure)}
      ></div>
      <div
        className="overallPerformancesBar novice"
        style={getClcStyle(data.cognitive_competence_score_novice)}
      ></div>
      <div
        className="overallPerformancesBar warning"
        style={getClcStyle(data.cognitive_competence_score_warning)}
      ></div>
    </div>
  );
};

export default ClcBar;
