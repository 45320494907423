// Utility functions
export const escapeHtml = (unsafe) =>
  unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");

export const truncateWithTooltip = (value, maxLength = 200) => {
  if (value.length <= maxLength) return value;

  const truncated = value.substring(0, maxLength) + "...";
  return `<div data-hover-text="${escapeHtml(value)}">${escapeHtml(truncated)}</div>`;
};

export const formatLabel = (key: string) => {
  return key
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
}