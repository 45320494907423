import {
  clone,
  compact,
  create,
  find,
  forEach,
  isFunction,
  map,
} from 'lodash';
import generateUUID from '../../generateUUID';

import additional_diagnoses_text from "../../../../../../../lib/case_metadata/additional_diagnoses.txt";
import clinical_principles_text from "../../../../../../../lib/case_metadata/clinical_principles.txt";
import differential_diagnosis_text from "../../../../../../../lib/case_metadata/differential_diagnosis.txt";
import final_diagnoses_text from "../../../../../../../lib/case_metadata/final_diagnoses.txt";
import presenting_problems_text from "../../../../../../../lib/case_metadata/presenting_problems.txt";
import sciences_core_concepts_text from "../../../../../../../lib/case_metadata/sciences_core_concepts.txt";

const ADDITIONAL_DIAGNOSES = additional_diagnoses_text.split("\n");
const CLINICAL_PRINCIPLES = clinical_principles_text.split("\n");
const DIFFERENTIAL_DIAGNOSIS = differential_diagnosis_text.split("\n");
const FINAL_DIAGNOSES = final_diagnoses_text.split("\n");
const PRESENTING_PROBLEMS = presenting_problems_text.split("\n");
const SCIENCES_CORE_CONCEPTS = sciences_core_concepts_text.split("\n");

function DocType() {
  this.isRequired = false;
  this.isSyncable = false;
  this.shouldInit = false;
  this.shouldInitWith = false;
}

Object.defineProperties(DocType.prototype, {
  "required": { "get": function() { this.isRequired = true; return this; }},
  "notRequired": { "get": function() { this.isRequired = false; return this; }},
  "syncable": { "get": function() { this.isSyncable = true; return this; }},
  "notSyncable": { "get": function() { this.isSyncable = false; return this; }},
  "init": { "get": function() { this.shouldInit = true; return this; }},
  "notInit": { "get": function() { this.shouldInit = true; this.shouldInitWith = false; return this; }}
});

DocType.prototype.initWith = function(arg) {
  this.shouldInit = true;
  this.shouldInitWith = true;
  this.initWithArg = arg;
  return this;
};

DocType.prototype.withDefault = function(defaultValue) {
  this.defaultValue = defaultValue;
  return this;
};

DocType.prototype.editWith = function(arg) {
  this.shouldEditWith = arg;
  return this;
};

DocType.prototype.shouldEditWithArgs = function () {
  return {
    default: this.defaultValue
  };
};

DocType.prototype.generate = function(nodeDefinitions, context) {
  let initData;
  if (this.shouldInitWith) {
    initData = this.initWithArg;
  } else {
    initData = this.initDefault;
  }

  return this.generateWith(initData, nodeDefinitions, context);
};

DocType.prototype.generateWith = function(initData, nodeDefinitions, context) {
  if (isFunction(initData)) {
    return initData(nodeDefinitions, context);
  } else {
    return initData;
  }
};

DocType.prototype.generateChild = function(childData, nodeDefinitions, context) {
  if (isFunction(childData)) {
    return childData(nodeDefinitions, context);
  } else if (childData instanceof DocTypeRef) {
    // @ts-ignore TODO: Remove once converted to classes
    return childData.generate(nodeDefinitions, context);
  } else {
    return childData;
  }
};

DocType.prototype.dup = function() {
  return clone(this);
};

DocType.prototype.deref = function(nodeDefinitions) {
  return this;
};

DocType.prototype.withDisplayName = function(displayName) {
  this.displayNameString = displayName;
  return this;
};
DocType.prototype.displayName = function() {
  return this.displayNameString;
};


function DocTypeMore() {
}

DocTypeMore.prototype = create(DocType.prototype, {
  'constructor': DocTypeMore
});

export function DTMore() {
  return new DocTypeMore();
}

DocTypeMore.prototype.merge = function(typeDefinition) {
  const returnTypeDefinition = clone(typeDefinition);

  const transferFields = [
    "isRequired",
    "isSyncable",
    "shouldInit",
    "shouldInitWith",
    "initWithArg",
    "displayNameString"
  ];

  transferFields.forEach((instanceVariable) => {
    if (this[instanceVariable] !== undefined) {
      returnTypeDefinition[instanceVariable] = this[instanceVariable];
    }
  });

  return returnTypeDefinition;
};

function DocTypeRef(refName) {
  this.refName = refName;
}
DocTypeRef.prototype = create(DocType.prototype, {
  'constructor': DocTypeRef
});
export function DTRef(refName) {
  return new DocTypeRef(refName);
}
DocTypeRef.prototype.deref = function(nodeDefinitions) {
  const nodeDefinition = clone(nodeDefinitions[this.refName]);

  const transferFields = [
    "isRequired",
    "isSyncable",
    "shouldInit",
    "shouldInitWith",
    "initWithArg",
    "displayNameString"
  ];

  transferFields.forEach((instanceVariable) => {
    if (this[instanceVariable] !== undefined) {
      nodeDefinition[instanceVariable] = this[instanceVariable];
    }
  });

  return nodeDefinition.deref(nodeDefinitions);
};
DocTypeRef.prototype.generate = function(nodeDefinitions, context) {
  return this.deref(nodeDefinitions).generate(nodeDefinitions, context);
};
DocTypeRef.prototype.generateOptions = function(nodeDefinitions) {
  return this.deref(nodeDefinitions).generateOptions(nodeDefinitions);
};
DocTypeRef.prototype.description = function(nodeDefinitions) {
  return this.deref(nodeDefinitions).description(nodeDefinitions);
};
DocTypeRef.prototype.displayName = function(nodeDefinitions) {
  return this.deref(nodeDefinitions).displayName(nodeDefinitions);
};


function DocTypeSubObject(refName, objectModel) {
  DocTypeRef.call(this, refName);
  this.objectModel = objectModel;
}
DocTypeSubObject.prototype = create(DocTypeRef.prototype, {
  'constructor': DocTypeSubObject
});
export function DTSubObject(refName, objectModel) {
  return new DocTypeSubObject(refName, objectModel);
}
DocTypeSubObject.prototype.deref = function(nodeDefinitions) {
  const newObject = DocTypeRef.prototype.deref.call(this, nodeDefinitions);

  newObject.objectModel = clone(newObject.objectModel);

  forEach(this.objectModel, (definitionOrReference, key) => {
    if (definitionOrReference instanceof DocTypeMore) {
          // @ts-ignore TODO: Remove once converted to classes
      newObject.objectModel[key] = definitionOrReference.merge(newObject.objectModel[key]);
    } else {
      newObject.objectModel[key] = definitionOrReference;
    }
  });

  return newObject;
};


function DocTypeString() {
  DocType.call(this);
  this.initDefault = "";
}
DocTypeString.prototype = create(DocType.prototype, {
  'constructor': DocTypeString
});
function DTString() {
  return new DocTypeString();
}



function DocTypeBoolean() {
  DocType.call(this);
  this.initDefault = false;
}
DocTypeBoolean.prototype = create(DocType.prototype, {
  'constructor': DocTypeBoolean
});
function DTBoolean() {
  return new DocTypeBoolean();
}



function DocTypeInteger() {
  DocType.call(this);
  this.initDefault = 0;
}
DocTypeInteger.prototype = create(DocType.prototype, {
  'constructor': DocTypeInteger
});
function DTInteger() {
  return new DocTypeInteger();
}



function DocTypeFloat() {
  DocType.call(this);
  this.initDefault = 0.0;
}
DocTypeFloat.prototype = create(DocType.prototype, {
  'constructor': DocTypeFloat
});
function DTFloat() {
  return new DocTypeFloat();
}



function DocTypeArray(docType) {
  DocType.call(this);
  this.docType = docType;
  this.initDefault = [];
}
DocTypeArray.prototype = create(DocType.prototype, {
  'constructor': DocTypeArray
});
DocTypeArray.prototype.generateWith = function(initData, nodeDefinitions, context) {
  if (isFunction(initData)) {
    return initData(nodeDefinitions, context);
  } else {
    return initData.map((childData) => {
      return this.init.generateChild(childData, nodeDefinitions, context);
    });
  }
};
DocTypeArray.prototype.generateOptions = function(nodeDefinitions) {
  return this.docType.generateOptions(nodeDefinitions);
};
DocTypeArray.prototype.shouldEditWithArgs = function () {
  return {
    ...this.docType.shouldEditWithArgs(),
    default: this.defaultValue
  };
};
export function DTArray(docType) {
  return new DocTypeArray(docType);
}



function DocTypeMap() {
  DocType.call(this);
  this.initDefault = {};
}
DocTypeMap.prototype = create(DocType.prototype, {
  'constructor': DocTypeMap
});
function DTMap() {
  return new DocTypeMap();
}



function DocTypeObject(objectModel) {
  DocType.call(this);
  this.initDefault = {};
  this.objectModel = objectModel;
}
Object.defineProperties(DocTypeObject.prototype, {
  "objectModel": { "set": function(objectModel) { this.objectModel = objectModel;}}
});
DocTypeObject.prototype = create(DocType.prototype, {
  'constructor': DocTypeObject
});
DocTypeObject.prototype.generateWith = function(initData, nodeDefinitions, context) {
  if (isFunction(initData)) {
    return initData(nodeDefinitions, context);
  } else {
    const generatedObject = {};
    forEach(this.objectModel, (definitionOrReference, key) => {
      if (key in initData) {
        generatedObject[key] = this.generateChild(initData[key], nodeDefinitions, context);
      } else {
        if (definitionOrReference instanceof DocType) {
          // @ts-ignore TODO: Remove once converted to classes
          if (definitionOrReference.shouldInit) {
            // @ts-ignore TODO: Remove once converted to classes
            generatedObject[key] = definitionOrReference.generate(nodeDefinitions, context);
          }
        } else {
          generatedObject[key] = definitionOrReference;
        }
      }
    });
    return generatedObject;
  }
};

DocTypeObject.prototype.generateEditWithList = function() {
  return compact(map(this.objectModel, (definitionOrReference, key) => {
    if (!(definitionOrReference instanceof DocType)) return null;

    // @ts-ignore TODO: Remove once converted to classes
    const editWith = definitionOrReference.shouldEditWith;
    if (!editWith) return null;

    // @ts-ignore TODO: Remove once converted to classes
    const displayName = definitionOrReference.displayName()

    // @ts-ignore TODO: Remove once converted to classes
    return [key, editWith, definitionOrReference.shouldEditWithArgs(), displayName];
  }));
};


DocTypeObject.prototype.description = function(nodeDefinitions) {
  return this.objectModel["subtype"] || this.objectModel["type"];
};
DocTypeObject.prototype.displayName = function(nodeDefinitions) {
  return this.displayNameString || this.objectModel["subtype"] || this.objectModel["type"];
};
DocTypeObject.prototype.deref = function(nodeDefinitions) {
  return this;
};
function DTObject(objectModel) {
  return new DocTypeObject(objectModel);
}

function DocTypeOr() {
  DocType.call(this);
  this.orTypes = Array.prototype.slice.call(arguments);
}
DocTypeOr.prototype = create(DocType.prototype, {
  'constructor': DocTypeOr
});
DocTypeOr.prototype.generateOptions = function(nodeDefinitions) {
  return this.orTypes;
};
function DTOr(...args) {
  // @ts-ignore TODO: Remove once converted to classes
  return new DocTypeOr(...Array.prototype.slice.call(args));
}

function DocTypeEnum() {
  DocType.call(this);
  this.enumOptions = Array.prototype.slice.call(arguments);
}
DocTypeEnum.prototype = create(DocType.prototype, {
  'constructor': DocTypeEnum
});
DocTypeEnum.prototype.generateOptions = function(nodeDefinitions) {
  return this.enumOptions;
};
DocTypeEnum.prototype.shouldEditWithArgs = function() {
  return {
    options: this.enumOptions,
    default: this.defaultValue
  };
};
function DTEnum(...args) {
  // @ts-ignore TODO: Remove once converted to classes
  return new DocTypeEnum(...Array.prototype.slice.call(args));
}

function newStartWithInlineContent(contentKey) {
  return {
    contentKey: contentKey,
    range: {
      start: 0,
      end: 0
    }
  };
}

export const nodeDefinitions = {
  uid: DTString().initWith(generateUUID),
  sectionContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("table"),
    DTRef("tp_clinical_reasoning"),
    DTRef("tp_clinical_skills"),
    DTRef("tp_knowledge"),
    DTRef("tp_management"),
    DTRef("tp_principles"),
    DTRef("tp_studies"),
    DTRef("video"),
  )),
  answerCommentContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("RLOModal"),
    DTRef("tp_clinical_reasoning"),
    DTRef("tp_clinical_skills"),
    DTRef("tp_knowledge"),
    DTRef("tp_management"),
    DTRef("tp_principles"),
    DTRef("tp_studies"),
    DTRef("video"),
  )),
  alertContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  categoryMatcherContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  dialogBoxContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("video"),
  )),
  neutralTextContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  harmStatementContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  modMapCardContent: DTArray(DTOr(
    DTRef("underlying_core_concepts"),
    DTRef("mod_map_image"),
  )),
  coreConceptContent: DTArray(DTOr(
    DTRef("core_concept_header"),
    DTRef("RLOModal"),
  )),
  deepDiveContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("RLOModal"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  referencesContent: DTArray(DTOr(
    DTRef("horizontalRule"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
  )),
  freeTextContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  questionContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  summaryStatementContent: DTArray(DTOr(
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  listItemContent: DTArray(DTOr(
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("numberedList"),
    DTRef("table"),
    DTRef("image")
  )),
  tableCellContent: DTArray(DTOr(
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
  )),
  tpContent: DTArray(DTOr(
    DTRef("alert"),
    DTRef("audio"),
    DTRef("horizontalRule"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("numberedList"),
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("RLOModal"),
    DTRef("section"),
    DTRef("table"),
    DTRef("video"),
  )),
  styledChildren: DTOr(
    DTString(),
    DTArray(DTOr(
      DTRef("text"),
      DTRef("bold"),
      DTRef("italic"),
      DTRef("underline"),
      DTRef("superscript"),
      DTRef("subscript"),
      DTRef("link"),
      DTRef("color"),
      DTRef("modalMedia")
    ))
  ).initWith(""),
  section: DTObject({
    type: "section",
    uid: DTRef("uid").init,
    level: DTInteger(),
    title: DTRef("styledChildren").init,
    content: DTRef("sectionContent").init,
    nested: DTBoolean().withDefault(false).initWith(true),
    collapsible: DTBoolean().withDefault(false).editWith("booleanInput"),
    collapsed: DTBoolean().withDefault(false).editWith("booleanInput"),
    classes: DTArray(DTString()).withDefault([]).editWith("tagsInput"),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false).editWith("booleanInput"),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: "title",
  }).withDisplayName("Section Header"),
  listItem: DTObject({
    type: "listItem",
    uid: DTRef("uid").init,
    content: DTRef("listItemContent").initWith([DTRef("paragraph").init]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }),
  openListItem: DTSubObject("listItem", {
    content: DTMore().initWith([DTRef("paragraph").init]),
  }),
  table: DTObject({
    type: "table",
    uid: DTRef("uid").init,
    rows: DTArray(DTRef("tableRow")).initWith([DTRef("tableRow").init]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }).withDisplayName("Table"),
  tableRow: DTObject({
    type: "tableRow",
    uid: DTRef("uid").init,
    cells: DTArray(DTRef("tableCell")).initWith([DTRef("tableCell").init,DTRef("tableCell").init]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }),
  tableCell: DTObject({
    type: "tableCell",
    uid: DTRef("uid").init,
    content: DTArray(DTRef("tableCellContent")).initWith([DTRef("paragraph")]).init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }),
  textInput: DTObject({
    type: "textInput",
    uid: DTRef("uid").init,
    value: DTString(),
    characterLimit: DTInteger().initWith(1000).editWith("integerTextInput"),
    boxLines: DTInteger().initWith(5).editWith("integerTextInput"),
    graded: DTBoolean().withDefault(false),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }),
  multipleChoice: DTObject({
    type: "multipleChoice",
    uid: DTRef("uid").init,
    multipleSelect: DTBoolean().initWith(true).editWith("booleanInput"),
    content: DTArray(DTRef("multipleChoiceAnswer")).initWith([DTRef("multipleChoiceAnswer").init, DTRef("multipleChoiceAnswer").init, DTRef("multipleChoiceAnswer").init, DTRef("multipleChoiceAnswer").init, DTRef("multipleChoiceAnswer").init]),
    graded: DTBoolean().withDefault(false),
    classes: DTArray(DTString()).withDefault([]).editWith("tagsInput"),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }),
  multipleChoiceAnswer: DTObject({
    type: "multipleChoiceAnswer",
    uid: DTRef("uid").init,
    content: DTRef("styledChildren").init,
    value: DTBoolean(),
    answer: DTBoolean(),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    _newStartWith: "content"
  }),
  cdq_case_content: DTArray(DTOr(
    DTRef("paragraph"),
    DTRef("regularList"),
    DTRef("numberedList"),
    DTRef("table"),
    DTRef("image"),
    DTRef("imageGallery"),
    DTRef("video"),
    DTRef("audio"),
    DTRef("cdq_question"),
  )),
  cdq_case: DTSubObject("generic_page", {
    subtype: 'cdq_case',
    classes: DTMore().initWith(['card', 'cdq_case']),
    title: DTString().initWith("Mr. Smith"),
    content: DTRef("cdq_case_content").initWith([
      DTRef("paragraph"),
      DTRef("cdq_question")
    ]),
    cdqFeatures: DTArray(DTRef("cdqFeature")).initWith([DTRef("cdqFeature").init, DTRef("cdqFeature").init, DTRef("cdqFeature").init]),
  }),
  cdqFeature: DTObject({
    type: "cdqFeature",
    uid: DTRef("uid").init,
    content: DTString().initWith("Feature"),
    _newStartWith: "content",
  }),
  cdqQuestion: DTObject({
    type: "cdqQuestion",
    uid: DTRef("uid").init,
    limit: DTOr(DTInteger(), DTBoolean()).withDefault(false).initWith(false).editWith("falseOrNumberInput"),
    isExplicitLimit: DTBoolean().withDefault(false).editWith("booleanInput"),
    content: DTArray(DTRef("cdqQuestionAnswer")).initWith([DTRef("cdqQuestionAnswer").init, DTRef("cdqQuestionAnswer").init, DTRef("cdqQuestionAnswer").init]),
    gradingRules: DTEnum('strict', 'loose').withDefault('strict').editWith("selectInput"),
    shouldShowGrade: DTBoolean().withDefault(false),
    graded: DTBoolean().withDefault(false),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }),
  cdqQuestionAnswer: DTObject({
    type: "cdqQuestionAnswer",
    uid: DTRef("uid").init,
    feature: DTString(),
    content: DTRef("styledChildren").init,
    value: DTBoolean(),
    answer: DTEnum(true, false, "poison"),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    _newStartWith: "content",
  }),
  categoryMatcher: DTObject({
    type: "categoryMatcher",
    uid: DTRef("uid").init,
    contentCategories: DTArray(DTRef("matchItem")).initWith([DTRef("matchItem").init]),
    contentItems: DTArray(DTRef("matchItem")).initWith([DTRef("matchItem").init]),
    value: DTMap(),
    answer: DTMap(),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }),
  matchItem: DTObject({
    type: "matchItem",
    uid: DTRef("uid").init,
    content: DTRef("styledChildren").init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: "content",
  }),
  button: DTObject({
    type: "button",
    title: DTRef("styledChildren").init,
    onClick: DTArray(DTRef("action")).init,
    uid: DTRef("uid").init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: "title",
  }),
  ratingBar: DTObject({
    type: "ratingBar",
    uid: DTRef("uid").init,
    maxValue: DTInteger().initWith(5).editWith("integerTextInput"),
    _newStartWith: {contentKey: "maxValue"}
  }),
  action: DTObject({
    type: "action",
    uid: DTRef("uid").init,
    arguments: DTMap()
  }),
  text: DTObject({
    type: "text",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  bold: DTObject({
    type: "bold",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  underline: DTObject({
    type: "underline",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  italic: DTObject({
    type: "italic",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  superscript: DTObject({
    type: "superscript",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  subscript: DTObject({
    type: "subscript",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  modalMedia: DTObject({
    type: "modalMedia",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  link: DTObject({
    type: "link",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  color: DTObject({
    type: "color",
    uid: DTRef("uid").init,
    content: DTRef("styledContent")
  }),
  root: DTSubObject("section", {
    subtype: 'root',
    content: DTArray(DTOr(
      DTRef("author"),
      DTRef("care_discussion_page"),
      DTRef("case_synopsis_page"),
      DTRef("clinical_reasoning_page"),
      DTRef("excellence_in_action_page"),
      DTRef("generic_page"),
      DTRef("history_page"),
      DTRef("learning_objectives_page"),
      DTRef("management_page"),
      DTRef("physical_exam_page"),
      DTRef("saq_page"),
      DTRef("teaching_page"),
      DTRef("testing_page"),
      DTRef("therapeutics_page"),
      DTRef("overview_page"),
      DTRef("clinical_feature_page"),
      DTRef("epidemiology_page"),
      DTRef("implications_for_management_page"),
      DTRef("implications_for_further_workup_page"),
      DTRef("mod_map_page"),
    )),
    nested: DTMore().initWith(false),
    classes: DTMore().initWith(["root"]),
    sex: DTEnum('unspecified', 'female', 'male', 'other').withDefault('unspecified').initWith('unspecified').editWith("selectInput").withDisplayName("Sex"),
    race: DTEnum('unknown', 'white', 'black', 'native_american_or_islander', 'asian', 'hispanic').withDefault('unknown').initWith('unknown').editWith("selectInput").withDisplayName("Race"),
    clinical_locations: DTArray(DTEnum(
      "Emergency department",
      "Home visit",
      "Inpatient/hospital",
      "Intensive care",
      "Outpatient clinic/urgent care",
      "Skilled nursing facility",
      "Other"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Clinical Locations"),
    clinical_disciplines: DTArray(DTEnum(
      "Critical Care",
      "Emergency Medicine",
      "Family Medicine",
      "Geriatrics",
      "Internal Medicine",
      "Neurology",
      "Obstetrics & Gynecology",
      "Palliative Care",
      "Pediatrics",
      "Psychiatry",
      "Radiology",
      "Surgery"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Clinical Disciplines"),
    systems: DTArray(DTEnum(
      "Allergy/Immune/Infection",
      "Cardiovascular",
      "Endocrine",
      "Female Reproductive & Breast",
      "Gastrointestinal",
      "Growth & Development",
      "Head & Neck",
      "Health & Wellness",
      "Hematopoietic",
      "Male Reproductive",
      "Metabolic & Nutrition",
      "Mind & Behavioral",
      "Musculoskeletal",
      "Nervous",
      "Renal & Urinary",
      "Respiratory",
      "Skin & Connective Tissue"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Systems"),
    clinical_focuses: DTArray(DTEnum(
      "Diagnosis",
      "Course of Illness",
      "Further Work Up",
      "Management",
      "Prevention"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Clinical Focuses"),
    age_groups: DTArray(DTEnum(
      "Newborn/Infant (0-1 year)",
      "Child (1-12 years)",
      "Adolescent (13-18 years)",
      "Adult (19-64 years)",
      "Older Adult / Elderly (65+ years)",
      "N/A"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Age Groups"),
    clinical_excellence: DTArray(DTEnum(
      "Diagnostic Excellence",
      "High Value Care",
      "Palliative Care",
      "Social Determinants of Health",
      "Telemedicine",
      "Trauma-Informed Care"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Clinical Excellence Integration"),
    clinical_principles: DTArray(DTEnum(...CLINICAL_PRINCIPLES)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Clinical Principles"),
    basic_science_disciplines: DTArray(DTEnum(
      "Anatomy",
      "Biochemistry",
      "Embryology",
      "Genetics and Genomics",
      "Immunology / Inflammation",
      "Microbiology",
      "Molecular Biology",
      "Neuroscience",
      "Pathology",
      "Pharmacology",
      "Physiology"
    )).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Sciences Integration"),
    sciences_core_concepts: DTArray(DTEnum(...SCIENCES_CORE_CONCEPTS)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Sciences Core Concepts"),
    presenting_problems: DTArray(DTEnum(...PRESENTING_PROBLEMS)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Presenting Problem"),
    final_diagnoses: DTArray(DTEnum(...FINAL_DIAGNOSES)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Final Diagnosis"),
    differential_diagnosis: DTArray(DTEnum(...DIFFERENTIAL_DIAGNOSIS)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Differential Diagnosis"),
    additional_diagnoses: DTArray(DTEnum(...ADDITIONAL_DIAGNOSES)).withDefault([]).initWith([]).editWith("multiSelectInput").withDisplayName("Additional Conditions"),
    collapsible: DTBoolean().withDefault(false).editWith("booleanInput"),
    disableDiagnoses: DTBoolean().withDefault(false).editWith("booleanInput"),
    disableNotes: DTBoolean().withDefault(false).editWith("booleanInput"),
    disableFindings: DTBoolean().withDefault(false).editWith("booleanInput"),
    disableBookmarks: DTBoolean().withDefault(false).editWith("booleanInput"),
    disableLabValues: DTBoolean().withDefault(true).editWith("booleanInput"),
    onlyOneUncollapsedSection: DTBoolean().withDefault(false).editWith("booleanInput"),
  }),

  generic_page: DTSubObject("section", {
    subtype: 'generic_page',
    content: DTArray(DTOr(
      DTRef("alert"),
      DTRef("audio"),
      DTRef("category_matcher_question"),
      DTRef("core_concept_header"),
      DTRef("causal_explanation"),
      DTRef("deep_dive"),
      DTRef("dialog_box"),
      DTRef("free_text_question"),
      DTRef("harm_statement"),
      DTRef("horizontalRule"),
      DTRef("image"),
      DTRef("imageGallery"),
      DTRef("mcq_diff_diagnosis"),
      DTRef("mcq_feq"),
      DTRef("mcq_multiple"),
      DTRef("mcq_no_best"),
      DTRef("mcq_single"),
      DTRef("neutral_text"),
      DTRef("numberedList"),
      DTRef("paragraph"),
      DTRef("references"),
      DTRef("regularList"),
      DTRef("RLOModal"),
      DTRef("section"),
      DTRef("summary_statement"),
      DTRef("table"),
      DTRef("tp_clinical_reasoning"),
      DTRef("tp_clinical_skills"),
      DTRef("tp_knowledge"),
      DTRef("tp_management"),
      DTRef("tp_principles"),
      DTRef("tp_studies"),
      DTRef("video"),
    )).init,
    classes: DTMore().initWith(["card"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
  }).withDisplayName("Page - Generic"),
  care_discussion_page: DTSubObject("generic_page", {
    subtype: "care_discussion_page",
    classes: DTMore().initWith(["card", "care_discussion_card"])
  }).withDisplayName("Page - Care Discussion"),
  case_synopsis_page: DTSubObject("section", {
    subtype: 'case_synopsis_page',
    content: DTArray(DTOr(
      DTRef("horizontalRule"),
      DTRef("image"),
      DTRef("numberedList"),
      DTRef("paragraph"),
      DTRef("regularList"),
      DTRef("section"),
      DTRef("table"),
    )).init,
    uid: DTMore().initWith('case_synopsis_card'),
    classes: DTMore().initWith(['card', 'case_synopsis_card']),
    title: DTString().initWith("Case Synopsis")
  }).withDisplayName("Page - Synopsis"),
  category_page: DTSubObject("generic_page", {
    subtype: "category_page",
    classes: DTMore().initWith(["card", "category_card"])
  }).withDisplayName("Page - Category"),
  clinical_reasoning_page: DTSubObject("generic_page", {
    subtype: "clinical_reasoning_page",
    classes: DTMore().initWith(["card", "clinical_reasoning_card"])
  }).withDisplayName("Page - Clinical Reasoning"),
  excellence_in_action_page: DTSubObject("generic_page", {
    subtype: "excellence_in_action_page",
    classes: DTMore().initWith(["card", "excellence_in_action_card"]),
    title: DTString().initWith("Excellence In Action"),
  }).withDisplayName("Page - Excellence In Action"),
  history_page: DTSubObject("generic_page", {
    subtype: "history_page",
    classes: DTMore().initWith(["card", "history_card"])
  }).withDisplayName("Page - History"),
  learning_objectives_page: DTSubObject("generic_page", {
    subtype: "learning_objectives_page",
    content: DTArray(DTOr(
      DTRef("learningObjectivesList")
    )).initWith([
      DTRef("learningObjectivesList").init
    ]),
    classes: DTMore().initWith(["card", "learning_objectives_card"]),
  }).withDisplayName("Page - Learning Objectives"),
  management_page: DTSubObject("generic_page", {
    subtype: "management_page",
    classes: DTMore().initWith(["card", "management_card"])
  }).withDisplayName("Page - Management"),
  physical_exam_page: DTSubObject("generic_page", {
    subtype: "physical_exam_page",
    classes: DTMore().initWith(["card", "physical_exam_card"])
  }).withDisplayName("Page - Physical Exam"),
  references_page: DTSubObject("generic_page", {
    subtype: "references_page",
    classes: DTMore().initWith(["card", "references_card"])
  }).withDisplayName("Page - References"),
  saq_page: DTSubObject("section", {
    subtype: 'saq_page',
    content: DTArray(DTOr(
      DTRef("category_matcher_question"),
      DTRef("free_text_question"),
      DTRef("mcq_diff_diagnosis"),
      DTRef("mcq_feq"),
      DTRef("mcq_multiple"),
      DTRef("mcq_no_best"),
      DTRef("mcq_single"),
    )).init,
    classes: DTMore().initWith(['card', 'saq_card']),
    title: DTString().initWith("Assessment Question"),
  }).withDisplayName("Page - SAQ"),
  teaching_page: DTSubObject("generic_page", {
    subtype: "teaching_page",
    classes: DTMore().initWith(["card", "teaching_card"])
  }).withDisplayName("Page - Teaching"),
  testing_page: DTSubObject("generic_page", {
    subtype: "testing_page",
    classes: DTMore().initWith(["card", "testing_card"])
  }).withDisplayName("Page - Testing"),
  therapeutics_page: DTSubObject("generic_page", {
    subtype: "therapeutics_page",
    classes: DTMore().initWith(["card", "therapeutics_card"])
  }).withDisplayName("Page - Therapeutics"),
  overview_page: DTSubObject("overview_card", {
    subtype: "overview_page",
    classes: DTMore().initWith(["card", "overview_card"]),
    title: DTString().initWith("Overview")
  }).withDisplayName("Page - Overview"),
  clinical_feature_page: DTSubObject("clinical_feature_card", {
    subtype: "clinical_feature_page",
    classes: DTMore().initWith(["card", "clinical_feature_card"]),
    title: DTString().initWith("Clinical Feature")
  }).withDisplayName("Page - Clinical Feature"),
  epidemiology_page: DTSubObject("epidemiology_card", {
    subtype: "epidemiology_page",
    classes: DTMore().initWith(["card", "epidemiology_card"]),
    title: DTString().initWith("Epidemiology")
  }).withDisplayName("Page - Epidemiology"),
  implications_for_management_page: DTSubObject("implications_for_management_card", {
    subtype: "implications_for_management_page",
    classes: DTMore().initWith(["card", "implications_for_management_card"]),
    title: DTString().initWith("Implications for Further Management")
  }).withDisplayName("Page - Implications for Further Management"),
  implications_for_further_workup_page: DTSubObject("implications_for_further_workup_card", {
    subtype: "implications_for_further_workup_page",
    classes: DTMore().initWith(["card", "implications_for_further_workup_card"]),
    title: DTString().initWith("Implications for Further Workup")
  }).withDisplayName("Page - Implications for Further Workup"),
  mod_map_page: DTSubObject("mod_map_card", {
    subtype: "mod_map_page",
    classes: DTMore().initWith(["card", "mod_map_card"]),
    title: DTString().initWith("MOD Map (Mechanism of Disease & Underlying Concepts)")
  }).withDisplayName("Page - MOD Map (Mechanism of Disease & Underlying Concepts)"),
  alert: DTSubObject("section", {
    subtype: 'alert',
    content: DTRef("alertContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["alert"]),
  }).withDisplayName("Alert"),
  audio: DTObject({
    type: "audio",
    uid: DTRef("uid").init,
    title: DTRef("styledChildren").init,
    source: DTEnum("local").initWith("local"),
    url: DTString().init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: {menu: 'mediaPicker'}
  }).withDisplayName("Audio"),
  category_matcher_question: DTSubObject("question", {
    subtype: 'category_matcher_question',
    content: DTRef("categoryMatcherContent").initWith([DTRef("paragraph").init, DTRef("categoryMatcher").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - Matching"),
  causal_explanation: DTSubObject("section", {
    subtype: 'causal_explanation',
    title: DTString().initWith("Causal Explanation (Why?)"),
    content: DTRef("deepDiveContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["causal_explanation"]),
  }).withDisplayName("Causal Explanation"),
  deep_dive: DTSubObject("section", {
    subtype: 'deep_dive',
    title: DTString().initWith(""),
    content: DTRef("deepDiveContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["expert_comment"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
  }).withDisplayName("Deep Dive"),
  dialog_box: DTSubObject("section", {
    subtype: 'dialog_box',
    content: DTRef("dialogBoxContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["dialog_box"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
  }).withDisplayName("Dialog Box"),
  free_text_question: DTSubObject("question", {
    classes: DTMore().initWith(["question", "free_text_question"]),
    subtype: 'free_text_question',
    content: DTRef("freeTextContent").initWith([DTRef("paragraph").init, DTRef("textInput").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - Free Text "),
  harm_statement: DTSubObject("section", {
    subtype: 'harm_statement',
    content: DTRef("harmStatementContent").initWith([DTRef("paragraph").init]),
    title: DTString().initWith("Harm Statement"),
    classes: DTMore().initWith(["harm_statement"]),
  }).withDisplayName("Harm Statement"),
  horizontalRule: DTObject({
    type: "horizontalRule",
    uid: DTRef("uid").init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }).withDisplayName("Horizontal Rule"),
  image: DTObject({
    type: "image",
    uid: DTRef("uid").init,
    title: DTRef("styledChildren").init,
    source: DTEnum("local").initWith("local"),
    url: DTString().init,
    classes: DTArray(DTString()).withDefault([]).editWith("tagsInput"),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
    alignment: DTEnum("left", "right", "none").withDefault("none").editWith("selectInput"),
    _newStartWith: {menu: 'mediaPicker'}
  }).withDisplayName("Image"),
  imageGallery: DTObject({
    type: "imageGallery",
    uid: DTRef("uid").init,
    images: DTArray(DTRef("image")).initWith([DTRef("image").init]),
    classes: DTArray(DTString()).withDefault([]).editWith("tagsInput"),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }).withDisplayName("Image Gallery"),
  learningObjective: DTObject({
    type: "learningObjective",
    uid: DTRef("uid").init,
    content: DTRef("styledChildren").init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }).withDisplayName("Learning Objective"),
  learningObjectivesList: DTObject({
    type: "learningObjectivesList",
    uid: DTRef("uid").init,
    content: DTArray(DTRef("learningObjective")).initWith([]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
  }).withDisplayName("Learning Objective List"),
  mcq_diff_diagnosis: DTSubObject("question", {
    subtype: 'mcq_diff_diagnosis',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init, DTRef("multipleChoice").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - MCQ Differential Diagnosis"),
  mcq_feq: DTSubObject("question", {
    subtype: 'mcq_feq',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init, DTRef("multipleChoice").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - MCQ FEQ"),
  mcq_multiple: DTSubObject("question", {
    subtype: 'mcq_multiple',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init, DTRef("paragraph").initWith({content: "<strong>Select all that apply.</strong>"}), DTRef("multipleChoice").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - MCQ Multiple Response"),
  mcq_no_best: DTSubObject("question", {
    subtype: 'mcq_no_best',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init, DTRef("paragraph").initWith({content: "<strong>Select all that apply.</strong>"}), DTRef("multipleChoice").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - No Single Best Answer"),
  mcq_single: DTSubObject("question", {
    subtype: 'mcq_single',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init, DTRef("paragraph").initWith({content: "<strong>Choose the single best answer.</strong>"}), DTRef("multipleChoice").initWith({multipleSelect: false}), DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Question Item - MCQ Single Answer"),
  neutral_text: DTSubObject("section", {
    subtype: 'neutral_text',
    content: DTRef("neutralTextContent").initWith([DTRef("paragraph").init]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
    classes: DTMore().initWith(["neutral_text"]),
  }).withDisplayName("Neutral Text Expand"),
  numberedList: DTObject({
    type: "numberedList",
    uid: DTRef("uid").init,
    content: DTArray(DTRef("listItem")).initWith([DTRef("listItem").init]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }).withDisplayName("List - Numbered"),
  paragraph: DTObject({
    type: "paragraph",
    uid: DTRef("uid").init,
    content: DTRef("styledChildren").init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false).editWith("booleanInput"),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: "content",
  }).withDisplayName("Paragraph"),
  references: DTSubObject("section", {
    subtype: 'references',
    title: DTString().initWith("References"),
    content: DTRef("referencesContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["references"]),
    _newStartWith: undefined,
  }).withDisplayName("References"),
  regularList: DTObject({
    type: "regularList",
    uid: DTRef("uid").init,
    content: DTArray(DTRef("listItem")).initWith([DTRef("listItem").init]),
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
  }).withDisplayName("List - Bulleted"),
  RLOModal: DTObject({
    type: "RLOModal",
    uid: DTRef("uid").init,
    title: DTRef("styledChildren").init,
    content: DTRef("alertContent").initWith([DTRef("paragraph").init]),
    classes: DTArray(DTString()).withDefault([]).editWith("tagsInput"),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false).editWith("booleanInput"),
    bookmarked: DTBoolean().withDefault(false),
    nested: DTBoolean().withDefault(false).initWith(true),
    collapsible: DTBoolean().withDefault(false).editWith("booleanInput"),
    collapsed: DTBoolean().withDefault(false).editWith("booleanInput"),
  }).withDisplayName("Reusable Content"),
  summary_statement: DTSubObject("question", {
    subtype: 'summary_statement',
    classes: DTMore().initWith(["question", "summary_statement_question"]),
    title: DTString().initWith("Summary Statement Question"),
    content: DTRef("summaryStatementContent").initWith([DTRef("paragraph").init, DTRef("textInput").init, DTRef("submit_button").init, DTRef("answer_comment").init]),
  }).withDisplayName("Summary Statement"),
  tp_clinical_reasoning: DTSubObject("section", {
    subtype: 'tp_clinical_reasoning',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["clinical_reasoning"]),
  }).withDisplayName("TP - Clinical Reasoning"),
  tp_clinical_skills: DTSubObject("section", {
    subtype: 'tp_clinical_skills',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["clinical_skills"]),
  }).withDisplayName("TP - Clinical Skills"),
  tp_knowledge: DTSubObject("section", {
    subtype: 'tp_knowledge',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["knowledge"]),
  }).withDisplayName("TP - Knowledge"),
  tp_management: DTSubObject("section", {
    subtype: 'tp_management',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["management"]),
  }).withDisplayName("TP - Management"),
  tp_principles: DTSubObject("section", {
    subtype: 'tp_principles',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["principles"]),
  }).withDisplayName("TP - Principles"),
  tp_studies: DTSubObject("section", {
    subtype: 'tp_studies',
    content: DTRef("tpContent").initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["studies"]),
  }).withDisplayName("TP - Studies"),
  video: DTObject({
    type: "video",
    uid: DTRef("uid").init,
    title: DTRef("styledChildren").init,
    source: DTEnum("local", "youtube", "vimeo").initWith("local"),
    url: DTString().init,
    classes: DTArray(DTString()).withDefault([]),
    inactive: DTBoolean().withDefault(0),
    invisible: DTBoolean().withDefault(false),
    bookmarked: DTBoolean().withDefault(false),
    _newStartWith: {menu: 'mediaPicker'}
  }).withDisplayName("Video"),
  answer_comment: DTSubObject("section", {
    subtype: 'answer_comment',
    content: DTRef("answerCommentContent").initWith([DTRef("paragraph").init]),
    title: DTString().initWith("Answer Comment"),
    classes: DTMore().initWith(["answer_comment"]),
    invisible: DTMore().initWith(true)
  }),
  submit_button: DTSubObject("button", {
    subtype: 'submit_button',
    title: DTString().initWith("Submit")
  }),
  save_button: DTSubObject("button", {
    subtype: 'submit_button',
    title: DTString().initWith("Save")
  }),
  question: DTSubObject("section", {
    subtype: 'question',
    content: DTRef("questionContent").initWith([DTRef("paragraph").init]),
    title: DTString().initWith("Question"),
    classes: DTMore().initWith(["question"]),
    _newStartWith: undefined,
  }).withDisplayName("Question"),
  cdq_question: DTSubObject("question", {
    classes: DTMore().initWith(["question"]),
    subtype: 'cdq_question',
    content: DTMore().initWith([DTRef("paragraph").init, DTRef("cdqQuestion").init, DTRef("submit_button").init]),
  }),
  finish_case_page: DTSubObject("generic_page", {
    subtype: 'finish_case_page',
    uid: DTMore().initWith('finish_case_card'),
    classes: DTMore().initWith(['card', 'finish_case_card']),
    title: DTString().initWith("Case Summary Download")
  }),
  release_notes_page: DTSubObject("generic_page", {
    subtype: 'release_notes_page',
    uid: DTMore().initWith('release_notes_card'),
    classes: DTMore().initWith(['card', 'release_notes_card']),
    title: DTString().initWith("Release Notes"),
    content: DTArray(DTOr(
      DTRef("release_notes_section")
    )).init
  }).withDisplayName("Release Notes"),
  release_notes_section: DTSubObject("section", {
    subtype: 'release_notes_section',
    classes: DTMore().initWith(['release_notes_section']),
    title: DTString().initWith("M_NAME DD, YYYY"),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
    content: DTArray(DTOr(
      DTRef("regularList"),
      DTRef("section")
    )).init
  }).withDisplayName("Release Notes Section"),
  content_updates: DTSubObject("section", {
    subtype: 'content_updates',
    classes: DTMore().initWith(['release_notes_updates_section']),
    title: DTString().initWith("Content Updates:"),
    content: DTArray(DTOr(DTRef("regularList"))).initWith([DTRef("regularList").init]),
  }),
  engagement_questions_updates: DTSubObject("section", {
    subtype: 'engagement_questions_updates',
    classes: DTMore().initWith(['release_notes_updates_section']),
    title: DTString().initWith("Engagement Questions:"),
    content: DTArray(DTOr(DTRef("regularList"))).initWith([DTRef("regularList").init]),
  }),
  self_assesment_questions_updates: DTSubObject("section", {
    subtype: 'self_assesment_questions_updates',
    classes: DTMore().initWith(['release_notes_updates_section']),
    title: DTString().initWith("Self-Assessment Questions:"),
    content: DTArray(DTOr(DTRef("regularList"))).initWith([DTRef("regularList").init]),
  }),
  references_updates: DTSubObject("section", {
    subtype: 'references_updates',
    classes: DTMore().initWith(['release_notes_updates_section']),
    title: DTString().initWith("References:"),
    content: DTArray(DTOr(DTRef("regularList"))).initWith([DTRef("regularList").init]),
  }),
  custom_updates: DTSubObject("section", {
    subtype: 'custom_updates',
    classes: DTMore().initWith(['release_notes_updates_section']),
    title: DTString().initWith(""),
    content: DTArray(DTOr(DTRef("regularList"))).initWith([DTRef("regularList").init]),
  }),
  signature_case_template: DTSubObject("root", {
    content: DTMore().initWith([
      DTRef("author"),
      DTRef("generic_page"),
      DTRef("finish_case_page"),
      DTRef("release_notes_page"),
      DTRef("learning_objectives_page").initWith({
        uid: 'learning_objectives_card',
        title: 'Learning Objectives',
      }),
      DTRef("case_synopsis_page")
    ])
  }),
  cdq_case_template: DTSubObject("root", {
    content: DTArray(DTOr(
      DTRef("cdq_case"),
    )).initWith([
      DTRef("cdq_case"),
    ]),
  }),
  test_case_template: DTSubObject("root", {
    content: DTMore().initWith([
      DTRef("learning_objectives_card"),
    ]),
  }),
  illness_script_template: DTSubObject("root", {
    content: DTArray(DTOr(
      DTRef("overview_card"),
      DTRef("epidemiology_card"),
      DTRef("clinical_feature_card"),
      DTRef("implications_for_further_workup_card"),
      DTRef("implications_for_management_card"),
      DTRef("mod_map_card"),
    )).initWith([
      DTRef("lead_editors"),
      DTRef("contributing_authors"),
      DTRef("overview_card"),
      DTRef("epidemiology_card"),
      DTRef("clinical_feature_card"),
      DTRef("clinical_feature_card"),
      DTRef("clinical_feature_card"),
      DTRef("clinical_feature_card"),
      DTRef("clinical_feature_card"),
      DTRef("clinical_feature_card"),
      DTRef("implications_for_further_workup_card"),
      DTRef("implications_for_management_card"),
      DTRef("mod_map_card"),
      DTRef("release_notes_page"),
      DTRef("synopsis_page"),
    ]),
    disableNotes: false,
    disableFeedback: false,
    disableFindings: true,
    disableBookmarks: false,
    disableDiagnoses: true,
    disableLabValues: false,
  }),
  question_vignette: DTSubObject("section", {
    subtype: 'question_vignette',
    title: DTString().initWith("Vignette"),
    content: DTArray(DTOr(
      DTRef("image"),
      DTRef("imageGallery"),
      DTRef("numberedList"),
      DTRef("paragraph"),
      DTRef("regularList"),
    )).initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["question_vignette"]),
  }),
  question_stem: DTSubObject("section", {
    subtype: 'question_stem',
    title: DTString().initWith("Stem"),
    content: DTArray(DTOr(
      DTRef("paragraph"),
    )).initWith([DTRef("paragraph").init]),
    classes: DTMore().initWith(["question_stem"]),
  }),
  assessment_question_mcq: DTSubObject("generic_page", {
    subtype: 'assessment_question_mcq',
    title: DTString().initWith("Question"),
    content: DTArray(DTOr(
      DTRef("question_vignette"),
      DTRef("question_stem"),
      DTRef("multipleChoice"),
    )).initWith([
      DTRef("question_vignette").init,
      DTRef("question_stem").init,
      DTRef("multipleChoice").initWith({multipleSelect: false})
    ]),
    classes: DTMore().initWith(["card", "assessment_question_mcq"]),
    collapsed: DTMore().initWith(false)
  }),
  assessment_question_mcq_template: DTSubObject("root", {
    content: DTArray(DTOr(
      DTRef("assessment_question_mcq")
    )).initWith([DTRef("assessment_question_mcq").init]),
  }),
  author: DTSubObject("paragraph", {
    content: DTString().initWith("<strong>Author(s):</strong>"),
    classes: DTMore().initWith(["authors"])
  }).withDisplayName("Authors"),
  lead_editors: DTSubObject("paragraph", {
    content: DTString().initWith("<strong>Lead Editors:</strong>"),
    classes: DTMore().initWith(["lead_editors"])
  }),
  contributing_authors: DTSubObject("paragraph", {
    content: DTString().initWith("<strong>Contributing Authors:</strong>"),
    classes: DTMore().initWith(["contributing_authors"])
  }),
  overview_card: DTSubObject("generic_page", {
    title: DTString().initWith("Overview"),
    classes: DTMore().initWith(["card", "overview_card"]),
    collapsed: DTMore().initWith(false),
    content: DTMore().initWith([
      DTRef("paragraph"),
    ])
  }).withDisplayName("Overview"),
  epidemiology_card: DTSubObject("generic_page", {
    title: DTString().initWith("Epidemiology"),
    classes: DTMore().initWith(["card", "epidemiology_card"]),
    content: DTMore().initWith([
      DTRef("paragraph"),
    ])
  }).withDisplayName("Epidemiology"),
  clinical_feature_card: DTSubObject("generic_page", {
    title: DTString().initWith("Clinical Feature"),
    classes: DTMore().initWith(["card", "clinical_feature_card"]),
    content: DTMore().initWith([
      DTRef("paragraph"),
      DTRef("section"),
    ])
  }),
  mod_map_card: DTSubObject("generic_page", {
    title: DTString().initWith("Mechanism of Disease & Underlying Concepts"),
    classes: DTMore().initWith(["card", "mod_map_card"]),
    content: DTRef("modMapCardContent").initWith([
      DTRef("mod_map_image"),
      DTRef("underlying_core_concepts"),
    ])
  }),
  mod_map_image: DTSubObject("image", {
    alignment: DTMore().initWith("left"),
    classes: DTMore().initWith(["mod-map-image"])
  }).withDisplayName("MOD Map Image"),
  synopsis_page: DTSubObject("section", {
    subtype: 'synopsis_page',
    content: DTArray(DTOr(
      DTRef("horizontalRule"),
      DTRef("image"),
      DTRef("numberedList"),
      DTRef("paragraph"),
      DTRef("regularList"),
      DTRef("section"),
      DTRef("table"),
    )).initWith([
      DTSubObject("paragraph", {
        content: DTString().initWith("Building on the traditional illness script format, Integrated Illness Scripts embed basic science core concepts as causal and explanatory mechanisms associated with clinical features. Integrated Illness Scripts are designed specifically to promote cognitive integration and provide a framework for learners to link basic science and clinical knowledge.")
      }),
    ]),
    uid: DTMore().initWith('synopsis_card'),
    classes: DTMore().initWith(['card', 'synopsis_card']),
    title: DTString().initWith("Synopsis"),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(true),
    invisible: DTMore().initWith(true)
  }).withDisplayName("Page - Synopsis"),
  underlying_core_concepts: DTSubObject("section", {
    subtype: 'underlying_core_concepts',
    title: DTString().initWith("Underlying Core Concepts"),
    content: DTRef("coreConceptContent").initWith([DTRef("core_concept_header").init]),
    classes: DTMore().initWith(["underlying_core_concepts"])
  }).withDisplayName("Underlying Core Concepts"),
  core_concept_header: DTSubObject("section", {
    subtype: 'core_concept_header',
    content: DTMore().initWith([DTRef("paragraph")]),
    classes: DTMore().initWith(["core_concept_header"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(false)
  }).withDisplayName("Core Concept Header"),
  implications_for_further_workup_card: DTSubObject("generic_page", {
    title: DTString().initWith("Implications for Further Work Up"),
    classes: DTMore().initWith(["card", "implications_for_further_workup_card"]),
    content: DTMore().initWith([
      DTSubObject("paragraph", {
        content: DTString().initWith("Using what you now understand about the integrated illness script, enter your thoughts below about the implications for further workup for this condition.")
      }),
      DTSubObject("paragraph", {
        content: DTString().initWith("Your responses will be recorded in your Student Report and may also be viewed by faculty.")
      }),
      DTRef("implications_for_further_workup_notes")
    ])
  }),
  implications_for_further_workup_notes: DTSubObject("section", {
    title: DTString().initWith("Implications for Further Workup:"),
    content: DTMore().initWith([DTRef("implications_for_further_workup_response").init, DTRef("save_button").init]),
    classes: DTMore().initWith(["implications_for_further_workup_notes"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(false),
  }),
  implications_for_further_workup_response: DTSubObject("textInput", {
    uid: DTMore().initWith("implications_for_further_workup_response"),
  }),
  implications_for_management_card: DTSubObject("generic_page", {
    title: DTString().initWith("Implications for Management"),
    classes: DTMore().initWith(["card", "implications_for_management_card"]),
    content: DTMore().initWith([
      DTSubObject("paragraph", {
        content: DTString().initWith("Using what you now understand about the integrated illness script, enter your thoughts below about the implications for management for this condition.")
      }),
      DTSubObject("paragraph", {
        content: DTString().initWith("Your responses will be recorded in your Student Report and may also be viewed by faculty.")
      }),
      DTRef("implications_for_management_notes")
    ])
  }),
  implications_for_management_notes: DTSubObject("section", {
    title: DTString().initWith("Implications for Management:"),
    content: DTMore().initWith([DTRef("implications_for_management_response").init, DTRef("save_button").init]),
    classes: DTMore().initWith(["implications_for_management_notes"]),
    collapsible: DTMore().initWith(true),
    collapsed: DTMore().initWith(false),
  }),
  implications_for_management_response: DTSubObject("textInput", {
    uid: DTMore().initWith("implications_for_management_response"),
  }),
  unassigned_case_template: DTSubObject("root", {
    content: DTMore().initWith([
      DTRef("generic_page"),
    ])
  })
};

export const subtypeClassifiers = {
  section: [
    ["alert", (n) => n.classes?.includes("alert")],
    ["answer_comment", (n) => n.classes?.includes("answer_comment")],
    ["category_page", (n) => n.classes?.includes("category_card")],
    ["care_discussion_page", (n) => n.classes?.includes("care_discussion_card")],
    ["causal_explanation", (n) => n.classes?.includes("causal_explanation")],
    ["clinical_reasoning_page", (n) => n.classes?.includes("clinical_reasoning_card")],
    ["cdq_case", (n) => n.classes?.includes("cdq_case")],
    ["custom_updates", (n) => n.classes?.includes("release_notes_updates_section")],
    ["underlying_core_concepts", (n) => n.classes?.includes("underlying_core_concepts")],
    ["dialog_box", (n) => n.classes?.includes("dialog_box")],
    ["deep_dive", (n) => n.classes?.includes("expert_comment")],
    ["excellence_in_action_page", (n) => n.classes?.includes("excellence_in_action_card")],
    ["harm_statement", (n) => n.classes?.includes("harm_statement")],
    ["finish_case_page", (n) => n.classes?.includes("finish_case_card")],
    ["history_page", (n) => n.classes?.includes("history_card")],
    ["neutral_text", (n) => n.classes?.includes("neutral_text")],
    ["core_concept_header", (n) => n.classes?.includes("core_concept_header")],
    ["release_notes_section", (n) => n.classes?.includes("release_notes_section")],
    ["learning_objectives_page", (n) => n.classes?.includes("learning_objectives_card")],
    ["management_page", (n) => n.classes?.includes("management_card")],
    ["physical_exam_page", (n) => n.classes?.includes("physical_exam_card")],
    ["category_matcher_question", (n) => n.classes?.includes("question") && n.content.some((child) => child.type === "categoryMatcher")],
    ["free_text_question", (n) => n.classes?.includes("free_text_question")],
    ["summary_statement", (n) => n.classes?.includes("summary_statement_question")],
    ["question", (n) => n.classes?.includes("question")],
    ["references", (n) => n.classes?.includes("references")],
    ["references_page", (n) => n.classes?.includes("references_card")],
    ["release_notes_page", (n) => n.classes?.includes("release_notes_card")],
    ["saq_page", (n) => n.classes?.includes("saq_card")],
    ["testing_page", (n) => n.classes?.includes("testing_card")],
    ["teaching_page", (n) => n.classes?.includes("teaching_card")],
    ["therapeutics_page", (n) => n.classes?.includes("therapeutics_card")],
    ["mod_map_page", (n) => n.classes?.includes("mod_map_card")],
    ["implications_for_further_workup_page", (n) => n.classes?.includes("implications_for_further_workup_card")],
    ["implications_for_management_page", (n) => n.classes?.includes("implications_for_management_card")],
    ["overview_page", (n) => n.classes?.includes("overview_card")],
    ["overview_page", (n) => n.classes?.includes("synopsis_card")],
    ['epidemiology_page', (n) => n.classes?.includes("epidemiology_card")],
    ['clinical_feature_page', (n) => n.classes?.includes("clinical_feature_card")],
    ["case_synopsis_page", (n) => n.classes?.includes("case_synopsis_card")],
    ["generic_page", (n) => n.classes?.includes("card")],
    ["tp_clinical_reasoning", (n) => n.classes?.includes("clinical_reasoning")],
    ["tp_clinical_skills", (n) => n.classes?.includes("clinical_skills")],
    ["tp_knowledge", (n) => n.classes?.includes("knowledge")],
    ["tp_management", (n) => n.classes?.includes("management")],
    ["tp_principles", (n) => n.classes?.includes("principles")],
    ["tp_studies", (n) => n.classes?.includes("studies")],
    ["assessment_question_mcq", (n) => n.classes?.includes("assessment_question_mcq")],
    ["question_stem", (n) => n.classes?.includes("question_stem")],
    ["question_vignette", (n) => n.classes?.includes("question_vignette")],
    ["root", (n) => n.classes?.includes("root") || n.level === 0]
  ],
} as Record<"section", Array<[string, (n: DocSectionType<unknown>) => boolean]>>;

export function getSubType(node: DocBlockNode<unknown>) {
  const nodeClassifiers = subtypeClassifiers[node.type];
  if (nodeClassifiers) {
    const returnedClassifier =  find(nodeClassifiers, (classifier) => classifier[1](node));
    if (returnedClassifier) {
      return returnedClassifier[0];
    }
  }

  return node.subtype || node.type;
}
