import React from "react";

const categories = [
  { className: "excelBox", title: "Excel", description: "Certain, correct, & fast" },
  { className: "competentBox", title: "COMPETENT", description: "Certain & correct OR very certain & correct with hesitation" },
  { className: "unsureBox", title: "UNSURE", description: "Uncertain & correct OR certain & correct with hesitation" },
  { className: "noviceBox", title: "Novice", description: "Uncertain & Wrong" },
  { className: "warningBox", title: "WARNING", description: "Certain & Wrong" },
];

const Ibubble = () => {
  return (
    <div className="curricular-performance-legend">
      {categories.map(({ className, title, description }) => (
        <div className="indicatorBox" key={title}>
          <div className={`ColorBox ${className}`}></div>
          <div className="pl10">
            <p className="label GraphLegendText legendTitle">{title}</p>
            <p className="bottomSectionGraphLegendText">{description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ibubble;
