import * as React from "react";

interface AssessmentQuestionFormProps {
  editingContextExtra: EditingContextExtra
};

export default function AssessmentQuestionForm(props: AssessmentQuestionFormProps) {
  const [answerExplanation, setAnswerExplanation] = React.useState(null);

  const save = function() {
    if (answerExplanation) {
      props.editingContextExtra.setAssessmentQuestionData({
        ...props.editingContextExtra.assessmentQuestion,
        answer_explanation: answerExplanation
      })
      setAnswerExplanation(null)
    }
  }

  return (
    <div style={{padding: 10}}>
      <div>
        <h5 style={{paddingBottom: 10}}>Answer Explanation:</h5>
        <span>
          <textarea value={answerExplanation || props.editingContextExtra.assessmentQuestion.answer_explanation || ""} onChange={(e) => setAnswerExplanation(e.target.value)} />
        </span>
        <span>
          <button className='aq-button' onClick={save}>Save</button>
        </span>
      </div>
    </div>
  );
}
