import * as React from "react";

import ReasoningToolPanel from "./Panel";

interface ReasoningToolLabValuePanelProps {};

export default function ReasoningToolLabValuePanel(props: ReasoningToolLabValuePanelProps) {
  return (
    <ReasoningToolPanel
      key="Lab Values"
      title="Lab Values"
    >
      <table className="reasoning-tool-lab-values-table">
        <tbody>
          <tr>
            <td>
              <strong>Blood, Plasma, Serum</strong>
            </td>
            <td>
              <strong>Conventional Range</strong>
            </td>
            <td>
              <strong>SI Range</strong>
            </td>
          </tr>
          <tr>
            <td>Alanine aminotransferase (ALT), serum</td>
            <td>&lt;45 U/L [male] &lt;34 U/L [female]</td>
            <td>&lt;0.77 μkat/L [male] &lt;0.58 μkat/L [female]</td>
          </tr>
          <tr>
            <td>Alkaline phosphatase (IFCC at 37<sup>o</sup>C)</td>
            <td>39-117 U/L</td>
            <td>0.65-1.95 mmol/L</td>
          </tr>
          <tr>
            <td>Amylase, serum</td>
            <td>28-100 u/L</td>
            <td>0.48-1.70 μkat/L</td>
          </tr>
          <tr>
            <td>Aspartate aminotransferase (AST), serum</td>
            <td>&lt;35 U/L [male] &lt;31 U/L [female]</td>
            <td>&lt;0.60 μkat/L [male] &lt;0.53 μkat/L [female]</td>
          </tr>
          <tr>
            <td>Bilirubin, serum (adult) Total // Conjugated</td>
            <td>0-2.0 mg/dL // 0.0-0.2 mg/dL</td>
            <td>0-34 μmol/L // 0.0-3.4 μmol/L</td>
          </tr>
          <tr>
            <td>Calcium, serum</td>
            <td>8.6-10.2 mg/dL</td>
            <td>2.15-2.55 mmol/L</td>
          </tr>
          <tr>
            <td>Cholesterol, serum</td>
            <td>&lt;200 mg/dL</td>
            <td>&lt;5.18 mmol/L</td>
          </tr>
          <tr>
            <td>Cortisol, serum</td>
            <td>0800 h: 0.6-1.6 μg/dL // 1600 h: 0.2-0.9 μg/dL</td>
            <td>17-44 nmol/L // 6-25 nmol/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>2200 h: 0.7-2.2 μg/dL</td>
            <td>2-6 nmol/L</td>
          </tr>
          <tr>
            <td>Creatine kinase, serum</td>
            <td>46-171 U/L [male] 34-145 U/L [female]</td>
            <td>0.78-2.90 μkat/L [male] 0.58-2.47 μkat/L [female]</td>
          </tr>
          <tr>
            <td>Creatinine, serum</td>
            <td>1.0-1.7 mg/dL [male] 0.6-1.3 mg/dL [female]</td>
            <td>88-150 μmol/L [male] 53-115 μmol/L [female]</td>
          </tr>
          <tr>
            <td>Electrolytes, serum</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Sodium</td>
            <td>136-145 mEq/L</td>
            <td>136-145 mmol/L</td>
          </tr>
          <tr>
            <td>Potassium</td>
            <td>3.5-5.0 mEq/L</td>
            <td>3.5-5.0 mmol/L</td>
          </tr>
          <tr>
            <td>Chloride</td>
            <td>95-105 mEq/L</td>
            <td>95-105 mmol/L</td>
          </tr>
          <tr>
            <td>Bicarbonate</td>
            <td>22-28 mEq/L</td>
            <td>22-28 mmol/L</td>
          </tr>
          <tr>
            <td>Magnesium</td>
            <td>1.5-2.0 mEq/L</td>
            <td>0.75-1.0 mmol/L</td>
          </tr>
          <tr>
            <td>Estriol, total, serum (in pregnancy)</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>21-32 wks</td>
            <td>5-50 ng/mL</td>
            <td>17-174 nmol/L</td>
          </tr>
          <tr>
            <td>33-35 wks</td>
            <td>90-240 ng/mL</td>
            <td>312-833 nmol/L</td>
          </tr>
          <tr>
            <td>36-41 wks</td>
            <td>150-213 ng/mL</td>
            <td>521-739 nmol/L</td>
          </tr>
          <tr>
            <td>Ferritin, serum</td>
            <td>20-250 ng/mL [male] 10-120 ng/mL [female]</td>
            <td>20-250 μg/L [male] 10-120 μg/L [female]</td>
          </tr>
          <tr>
            <td>Follicle-stimulating hormone, serum/plasma</td>
            <td>Male: 1.4-15.4 mIU/mL</td>
            <td>1.4-15.4 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: follicular phase 1.4-9.9 mIU/mL</td>
            <td>1.4-9.9 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>midcycle peak 0.2-17.2 mIU/mL</td>
            <td>0.2-17.2 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>luteal phase 1.1-9.2 mIU/mL</td>
            <td>1.1-9.2 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>postmenopause 19.3-100.6 mIU/mL</td>
            <td>19.3-100.6 U/L</td>
          </tr>
          <tr>
            <td>Gases, arterial blood (room air)</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>pH</td>
            <td>7.35-7.45</td>
            <td>[H+] 36-44 nmol/L</td>
          </tr>
          <tr>
            <td>PC02</td>
            <td>33-45 mm Hg</td>
            <td>4.4-5.9 kPa</td>
          </tr>
          <tr>
            <td>P02</td>
            <td>75-105 mm Hg</td>
            <td>10.0-14.0 kPa</td>
          </tr>
          <tr>
            <td>Glucose, serum</td>
            <td>Fasting: 74-100 mg/dL</td>
            <td>4.1-5.5 mmol/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>2-hr postprandial: &gt;120 mg/dL</td>
            <td>&lt;6.6 mmol/L</td>
          </tr>
          <tr>
            <td>Growth hormone - arginine stimulation</td>
            <td>&gt;7.5 ng/mL</td>
            <td>&gt;7.5 μg/L</td>
          </tr>
          <tr>
            <td>Immunoglobulins, serum</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>IgA</td>
            <td>70-400 mg/dL</td>
            <td>0.-4.0 g/L</td>
          </tr>
          <tr>
            <td>IgE</td>
            <td>0-380 mg/dL</td>
            <td>0-380 g/L</td>
          </tr>
          <tr>
            <td>IgG</td>
            <td>700-1600 mg/dL</td>
            <td>7.0-16.0 g/L</td>
          </tr>
          <tr>
            <td>IgM</td>
            <td>40-230 mg/dL</td>
            <td>0.4-2.3 g/L</td>
          </tr>
          <tr>
            <td>Iron</td>
            <td>50-170 μg/dL</td>
            <td>9-30 μmol/L</td>
          </tr>
          <tr>
            <td>Lactate dehydrogenase, serum</td>
            <td>180-360 U/L</td>
            <td>3.1-6.1 μkat/L</td>
          </tr>
          <tr>
            <td>Luteinizing hormone, serum/plasma</td>
            <td>Male: 1.2-7.8 mIU/mL</td>
            <td>1.2-7.8 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: follicular phase 1.7-15.0 mIU/mL</td>
            <td>1.7-15.0 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>midcycle peak 21.9-56.6 mIU/mL</td>
            <td>21.9-56.6 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>luteal phase 0.6-16.3 mIU/mL</td>
            <td>0.6-16.3 IU/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>postmenopausal 14.2-52.3 mIU/mL</td>
            <td>14.2-52.3 IU/L</td>
          </tr>
          <tr>
            <td>Osmolality, serum</td>
            <td>275-295 mOsmol/kg H2O</td>
            <td>275-295 mOsmol/kg H2O</td>
          </tr>
          <tr>
            <td>Parathyroid hormone, serum, N-terminal</td>
            <td>10-65 pg/mL</td>
            <td>10-65 ng/L</td>
          </tr>
          <tr>
            <td>Phosphorus (inorganic), serum</td>
            <td>2.5-4.5 mg/dL</td>
            <td>0.80-1.44 mmol/L</td>
          </tr>
          <tr>
            <td>Prolactin, serum (hPRL)</td>
            <td>3.0-14.7 ng/mL [male] 3.8-23.0 ng/mL [female]</td>
            <td>3.0-14.7 μg/L [male] 3.8-23.0 μg/L [female]</td>
          </tr>
          <tr>
            <td>Proteins, serum</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>6.4-8.3 g/dL</td>
            <td>64-83 g/L</td>
          </tr>
          <tr>
            <td>Albumin</td>
            <td>3.5-5.2 g/dL</td>
            <td>35-52 g/L</td>
          </tr>
          <tr>
            <td>Thyroid-stimulating hormone, serum or plasma</td>
            <td>0.4-4.2 μIU/mL</td>
            <td>0.4-4.2 mIU/L</td>
          </tr>
          <tr>
            <td>Thyroxine (T4), serum</td>
            <td>4.6-10.5 μg/dL [male] 5.5-11.0 μg/dL [female]</td>
            <td>59-135 nmol/L [male] 65-138 nmol/L [female]</td>
          </tr>
          <tr>
            <td>Triglycerides, serum</td>
            <td>&lt;150 mg/dL</td>
            <td>&lt;1.70 mmol/L</td>
          </tr>
          <tr>
            <td>Triiodothyronine (T3), serum (RIA)</td>
            <td>20-50yr 70-204 ng/dL</td>
            <td>1.08-4.14 nmol/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>50-90yr 40-181 ng/dL</td>
            <td>0.62-2.79 nmol/L</td>
          </tr>
          <tr>
            <td>Urea nitrogen, serum</td>
            <td>6-20 mg/dL</td>
            <td>2.1-7.1 mmol/L</td>
          </tr>
          <tr>
            <td>Uric acid, serum</td>
            <td>4.4-7.6 mg/dL [male] 2.3-6.6 mg/dL [female]</td>
            <td>0.26-0.45 mmol/L [male] 0.13-0.39 mmol/L [female] </td>
          </tr>
          <tr>
            <td>
              <strong>Body Mass Index (BMI)</strong>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Body mass index</td>
            <td>Adult: 19-25 kg/m2</td>
            <td>Adult: 19-25 kg/m2</td>
          </tr>
          <tr>
            <td>
              <strong>Cerebrospinal Fluid</strong>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Cell count</td>
            <td>0-5 /mm3</td>
            <td>0-5 x 106/L</td>
          </tr>
          <tr>
            <td>Gamma globulin</td>
            <td>3%-12% total proteins</td>
            <td>0.03-0.12</td>
          </tr>
          <tr>
            <td>Glucose</td>
            <td>40-70 mg/dL</td>
            <td>2.2-3.9 mmol/L</td>
          </tr>
          <tr>
            <td>Pressure</td>
            <td>70-180 mm H2O</td>
            <td>70-180 mm H2O</td>
          </tr>
          <tr>
            <td>Proteins, total</td>
            <td>Ventricular fluid 5-15 mg/dL</td>
            <td>50-150 g/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Cisternal fluid 15-25 mg/dL</td>
            <td>150-250 g/L</td>
          </tr>
          <tr>
            <td>
              <strong>Hematologic</strong>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Bleeding time (template)</td>
            <td>2-7 minutes</td>
            <td>2-7 minutes</td>
          </tr>
          <tr>
            <td>Erythrocyte count</td>
            <td>Male: 4.3-5.9 million/mm<sup>3</sup>
            </td>
            <td>4.3-5.9 x10<sup>12</sup>/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female:3.5-5.5 million/mm<sup>3</sup>
            </td>
            <td>3.5-5.5 x10<sup>12</sup>/L</td>
          </tr>
          <tr>
            <td>Erythrocyte sedimentation rate (Westergren)</td>
            <td>Male: 1-15 mm/h</td>
            <td>1-15 mm/h</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: 1-20 mm/h</td>
            <td>1-20 mm/h</td>
          </tr>
          <tr>
            <td>Hematocrit</td>
            <td>Male: 37% - 51%</td>
            <td>0.37-0.51</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: 35%-47%</td>
            <td>0.35-0.47</td>
          </tr>
          <tr>
            <td>Hemoglobin A1c</td>
            <td>&lt; 6%</td>
            <td>&lt;0.06</td>
          </tr>
          <tr>
            <td>Hemoglobin, blood</td>
            <td>Male: 12.6-17.4 g/dL</td>
            <td>126-174 g/L</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: 11.7-16.1 g/dL</td>
            <td>117-161 g/L</td>
          </tr>
          <tr>
            <td>Hemoglobin, plasma</td>
            <td>&lt;3 mg/dL</td>
            <td>&lt;0.47 μmol/L</td>
          </tr>
          <tr>
            <td>Leukocyte count and differential</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Leukocyte count</td>
            <td>4.5-11 cells x 10<sup>3</sup>/μL</td>
            <td>4.5-11.0 cells x10<sup>9</sup>/L</td>
          </tr>
          <tr>
            <td>Segmented neutrophils</td>
            <td>50%-70%</td>
            <td>0.50-0.70</td>
          </tr>
          <tr>
            <td>Bands</td>
            <td>0%-5%</td>
            <td>0.00-0.05</td>
          </tr>
          <tr>
            <td>Eosinophils</td>
            <td>1%-5%</td>
            <td>0.01-0.05</td>
          </tr>
          <tr>
            <td>Basophils</td>
            <td>0%-1%</td>
            <td>0.00-1.00</td>
          </tr>
          <tr>
            <td>Lymphocytes</td>
            <td>20%-40%</td>
            <td>0.20-0.40</td>
          </tr>
          <tr>
            <td>Monocytes</td>
            <td>1%-6%</td>
            <td>0.01-0.06</td>
          </tr>
          <tr>
            <td>Mean corpuscular hemoglobin</td>
            <td>27-32 pg/cell</td>
            <td>1.68-1.99 fmol/cell</td>
          </tr>
          <tr>
            <td>Mean corpuscular hemoglobin concentraton</td>
            <td>31%-36% Hb/cell</td>
            <td>4.81-5.58 mmol Hb/L</td>
          </tr>
          <tr>
            <td>Mean corpuscular volume</td>
            <td>76-96 μm<sup>3</sup>
            </td>
            <td>76-96 fL</td>
          </tr>
          <tr>
            <td>Partial thromboplastin time (activated)</td>
            <td>20-34 seconds</td>
            <td>20-34 seconds</td>
          </tr>
          <tr>
            <td>Platelet count</td>
            <td>150000-400000 /mm<sup>3</sup>
            </td>
            <td>150-400 x10<sup>9</sup>/L</td>
          </tr>
          <tr>
            <td>Prothrombin time</td>
            <td>11-15 seconds</td>
            <td>11-15 seconds</td>
          </tr>
          <tr>
            <td>Reticulocyte count</td>
            <td>0.5%-1.5%</td>
            <td>0.005-0.015</td>
          </tr>
          <tr>
            <td>Thrombin time</td>
            <td>&lt;2 seconds deviation from control</td>
            <td>&lt;2 seconds deviation from control</td>
          </tr>
          <tr>
            <td>
              <strong>Sweat</strong>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Chloride</td>
            <td>5-35mEq/L</td>
            <td>5-35 mmol/L</td>
          </tr>
          <tr>
            <td>
              <strong>Urine</strong>
            </td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Chloride</td>
            <td>110-250 mEq/L</td>
            <td>110-250 mmol/L</td>
          </tr>
          <tr>
            <td>Creatinine clearance</td>
            <td>Male: 14-26 mg/kg/day</td>
            <td>124-230 μmol/kg/day</td>
          </tr>
          <tr>
            <td>
            </td>
            <td>Female: 11-20 mg/kg/day</td>
            <td>97-177 μmol/kg/day</td>
          </tr>
          <tr>
            <td>Estriol, total (in pregnancy)</td>
            <td>
            </td>
            <td>
            </td>
          </tr>
          <tr>
            <td>35 wks</td>
            <td>31-140 ng/mL</td>
            <td>108-486 nmol/L</td>
          </tr>
          <tr>
            <td>40 wks</td>
            <td>95-460 ng/mL</td>
            <td>330-1596 nmol/L</td>
          </tr>
          <tr>
            <td>Osmolality</td>
            <td>50-1400 mOsmol/kg H20</td>
            <td>
            </td>
          </tr>
          <tr>
            <td>Potassium</td>
            <td>25-125 mEq/L</td>
            <td>25-125 mmol/L</td>
          </tr>
          <tr>
            <td>Proteins, total</td>
            <td>&lt;100 mg/day</td>
            <td>&lt;0.1 mg/day</td>
          </tr>
          <tr>
            <td>Sodium</td>
            <td>40-220 mEq/day [male] 27-287 mEq/day [female]</td>
            <td>40-220 mmol/L [male] 27-287 mmol/L [female]</td>
          </tr>
          <tr>
            <td>Uric acid</td>
            <td>250-750 mg/day</td>
            <td>1.48-4.43 mmol/L </td>
          </tr>
          <tr>
            <td rowSpan={1} colSpan={3}>
              <strong>REFERENCES:</strong>
            </td>
          </tr>
          <tr>
            <td rowSpan={1} colSpan={3}>Bakerman S. Backerman's ABC's of Interpretive Laboratory Data. 4th ed. Scottsdale, AZ: Interpretive Laboratory Data, Inc.; 2002. </td>
          </tr>
          <tr>
            <td rowSpan={1} colSpan={3}>Burtis CA, Ashwood ER, Bruns DE. Tietz Textbook of Clinical Chemistry and Molecular Diagnostics. 4th ed. St. Louis, MI: Elsevier Inc.; 2006. </td>
          </tr>
        </tbody>
      </table>
    </ReasoningToolPanel>
  );
}
