import * as React from "react";

import ReasoningToolPanel from "./Panel";

import * as DocConvertHTML from "../../../helpers/Doc/Convert/PlainText";

import DocEditHeaderMetadataInputs from "../EditBar/EditHeader/MetadataInputs";
import DocPrerequisiteMetadata from "./CaseMetadataPanel/PrerequisiteMetadata";

interface CaseMetadataPanelProps {
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  userContext: UserContext;
  mergedData: MergedData;
};

export default function CaseMetadataPanel(props: CaseMetadataPanelProps) {
  const {
    editingContext,
    editingContextExtra,
    userContext,
    mergedData,
  } = props;

  const assessmentQuestionSignatureVersion = editingContextExtra.assessmentQuestionSignatureVersion;
  const node = assessmentQuestionSignatureVersion?.data || mergedData

  return (
    <ReasoningToolPanel
      key="CASE METADATA"
      title="CASE METADATA"
    >
      {assessmentQuestionSignatureVersion?.data?.title && <h5>{DocConvertHTML.fromAnyType(assessmentQuestionSignatureVersion?.data?.title)}</h5>}
      <DocEditHeaderMetadataInputs
        editingContext={editingContext}
        userContext={userContext}
        node={node}
        disableMetadataEdit={!!assessmentQuestionSignatureVersion || !userContext.isAdmin}
        onlyKeys={[
          "sex",
          "age_groups",
          "race",
          "clinical_locations",
          "clinical_disciplines",
          "systems",
          "clinical_focuses",
          "clinical_excellence",
          "clinical_principles",
          "basic_science_disciplines",
          "sciences_core_concepts",
          "presenting_problems",
          "final_diagnoses",
          "differential_diagnosis",
          "additional_diagnoses"
        ]}
      />
      <hr/>
      <DocPrerequisiteMetadata
        editingContext={editingContext}
        editingContextExtra={editingContextExtra}
        rootUid={node.uid}
        possible_prerequisite={node.possible_prerequisite}
        prerequisite_document_id={node.prerequisite_document_id}
        userContext={userContext}
      />
    </ReasoningToolPanel>
  );
}
