import * as React from "react";

import * as R from "ramda";

import * as classNames from "classnames";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";
import { displayModes } from "../../../helpers/Doc/displayMode";
import { currentTimeInSeconds } from "../../../helpers/time_format";

import DocCDQFeatures from "./CDQFeatures";
import DocSectionHeader from "./Section/Header";

import DocBlockNodes from "../BlockNodes";
import IntegrationsTags from "../../Integrations/component";

import DocFeedbackModal from "../Modals/FeedbackModal";
interface DocSectionProps extends DocSectionType<InlineNode>, BlockNodeAll {
  blockNodesContainsSelection?: boolean;
  currentMenu?: DocMenu;
  integrationInfo: IntegrationInfo;
  openSiblingSectionUIDs: Array<string>;
  paused?: boolean;
  roData: RoData;
  roDocumentCount: number;
  submitted?: boolean;
};

interface DocSectionState {
  timeLeftInSeconds?: number;
  showModal: boolean;
};

export default class DocSection extends React.PureComponent<DocSectionProps, DocSectionState> {
  constructor(props: DocSectionProps) {
    super(props);
    let startingState: DocSectionState = {
      showModal: localStorage.getItem("showModal") === "true",
    };

    const timeLeft = this.calculateTimeLeft();
    if (timeLeft) {
      startingState = {...startingState, timeLeftInSeconds: timeLeft};
    }

    this.state = startingState;
    this.handleShowModal = this.handleShowModal.bind(this);
  }

  private sectionRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  private timer?: ReturnType<typeof setTimeout>;
  private oldBoundingClientRect?: DOMRect;

  componentDidMount() {
    if (!this.props.userContext.isAdmin) { this.startTimer(); }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  startTimer() {
    if (this.props.timeLimit) {
      if (!this.props.currentTimeSegmentStart && this.props.timeUsedBeforeCurrentSegment && this.props.timeUsedBeforeCurrentSegment>0) {
        this.props.userContext.addState({
          [this.props.uid]: {currentTimeSegmentStart: Math.floor(new Date().getTime() / 1000)},
        });
      }

      this.timer = setInterval(this.tick.bind(this), 1000);
    }
  }

  calculateTimeLeft() {
    if (this.props.timeLimit) {
      let timeLeft = this.props.timeLimit;
      if (this.props.timeUsedBeforeCurrentSegment) {
        timeLeft = timeLeft - this.props.timeUsedBeforeCurrentSegment;
      }

      if (this.props.currentTimeSegmentStart) {
        timeLeft = timeLeft - (currentTimeInSeconds() - this.props.currentTimeSegmentStart);
      }

      return timeLeft;
    } else {
      return;
    }
  }

  tick() {
    if ( this.props.timeLimit && this.props.currentTimeSegmentStart ) {
      this.setState({timeLeftInSeconds: this.calculateTimeLeft()});
    }
  }

  componentWillUpdate(nextProps: DocSectionProps, nextState: DocSectionState) {
    if (this.props.collapsed !== nextProps.collapsed) {
      this.oldBoundingClientRect = this.sectionRef.current?.getBoundingClientRect();
    }
  }

  componentDidUpdate(prevProps: DocSectionProps, prevState: DocSectionState) {
    if ((prevProps.collapsed !== this.props.collapsed) && this.props.collapsed) {
      const oldBoundingClientRect = this.oldBoundingClientRect;
      const newBoundingClientRect = this.sectionRef.current?.getBoundingClientRect();
      if (oldBoundingClientRect && newBoundingClientRect) {
        if (oldBoundingClientRect.bottom < 0) {
          window.scrollBy(0,newBoundingClientRect.height - oldBoundingClientRect.height);
        } else if (oldBoundingClientRect.top < 0) {
          if (this.props.collapsed) {
            window.scrollBy(0,newBoundingClientRect.bottom - 70);
          }
        }
      }
    }
  }

  // Function to update showModal state
  handleShowModal() {
    localStorage.setItem("showModal", "true");
    setTimeout(() => {
      localStorage.removeItem("showModal");
    }, 5000);
  };

  render() {
    const {
      title,
      displayContext,
      userContext,
      content,
    } = this.props;

    const isFeedbackCard = this.props.classes?.includes("feedback_card");
    if (isFeedbackCard && displayContext.displayMode === displayModes.STUDENT) {
      const button = content.find((child) => child.type == "button")

      return <DocFeedbackModal
        displayContext={displayContext}
        userContext={userContext}
        onClick={button.onClick}
        onSubmitFinish={this.handleShowModal}
      />
    }

    if (!this.props.userContext.isAdmin) {
      if (this.props.submitted) {
        clearInterval(this.timer);
        this.props.userContext.submitExam("Your exam has been sucessfully submitted.");
        return null;
      } else if (this.props.timeLimit && this.state.timeLeftInSeconds && this.state.timeLeftInSeconds <= 0) {
        clearInterval(this.timer);
        this.props.userContext.submitExam("Your time has expired. Your exam has been sucessfully submitted.");
        return null;
      } else if (this.props.paused) {
        this.props.userContext.pauseExam();
        return null;
      }
    }

    let visibleContent = this.props.content;
    let shouldDisplayIntegrationTags = this.props.classes?.includes("case_synopsis_card") && this.props.featureFlag.integrations

    if (!this.props.displayContext.isDisplayFull) {
      if ((this.props.level !== 0 && !this.props.nested) || (this.props.collapsed) || (this.props.inactiveBool)) {
        visibleContent = [];
        shouldDisplayIntegrationTags = false;
      }
    }

    if (!this.props.displayContext.isUngated) {
      visibleContent = visibleContent.filter((node) => node && node.invisible !== true);
    }

    if (this.props.displayContext.onlyDisplaySectionTitles) {
      visibleContent = visibleContent.filter((node) => node.type === "section");
      shouldDisplayIntegrationTags = false;
    }

    let openSiblingSectionUIDs;
    if (this.props.level === 0 && !this.props.displayContext.canBeEditing) {
      openSiblingSectionUIDs = visibleContent.filter((node) => {
        return (
          node.type === "section"
          && node.collapsed !== true
          && node.collapsible === true
        );
      }).map((node) => node.uid);
    }

    const classes = classNames("doc-section", this.props.uid, this.props.classes,
      {
        nested: this.props.nested,
        collapsible: this.props.collapsible,
        collapsed: this.props.collapsed,
        inactive: this.props.inactiveBool,
        "focused-bookmark": this.props.isFocusedBookmark,
      });

    const isSummarySatementSection = typeof title === "string" && /^Summary statement/i.test(title);
    const isSaqCard = this.props.classes?.includes("saq_card");
    const isFinishCaseCard = this.props.classes?.includes("finish_case_card");
    let propsClone = {...this.props};

    if (displayContext.enableAiSummaryStatementFeedback && isSummarySatementSection) {
      const questionSectionIndex = visibleContent.findIndex((node) => node.type == "section" && node.classes?.includes("question"));
      const questionSection = visibleContent.find((node): node is DocSectionType<InlineNode> => node.type === "section" && !!node.classes?.includes("question"))

      const answerCommentIndex = questionSection?.content?.findIndex((node): node is DocSectionType<InlineNode> => node.type === "section" && !!node.classes?.includes("answer_comment"));
      const answerCommentSection = questionSection?.content?.find((node): node is DocSectionType<InlineNode> => node.type === "section" && !!node.classes?.includes("answer_comment"));
      const idealSummaryStatement = answerCommentSection?.content?.find((node): node is DocParagraphType<InlineNode> => node.type === "paragraph" && !(/<strong>Expert Answer:<\/strong>/.test(node.content)))?.content;
      const summaryStatementTextInput = questionSection?.content?.find((node): node is DocTextInputType => node.type === "textInput");
      const summaryStatementSubmitButtonIndex = questionSection?.content?.findIndex((node): node is DocButtonType<InlineNode> => node.type === "button");
      const summaryStatementSubmitButton = questionSection?.content?.find((node): node is DocButtonType<InlineNode> => node.type === "button");

      if (idealSummaryStatement && summaryStatementTextInput && summaryStatementSubmitButton) {
        const summaryStatementSubmitButtonActions = summaryStatementSubmitButton.onClick;

        visibleContent = R.over(R.lensPath([questionSectionIndex, "content"]), R.remove(answerCommentIndex, 1), visibleContent);
        visibleContent = R.over(R.lensPath([questionSectionIndex, "content"]), R.remove(summaryStatementSubmitButtonIndex, 1), visibleContent);

        summaryStatementTextInput.aiAnswerCommentSection = answerCommentSection;
        summaryStatementTextInput.aiSummaryStatementIdeal = idealSummaryStatement;
        summaryStatementTextInput.aiSummaryStatementAllowFeedback = true;
        summaryStatementTextInput.aiSubmitButtonActions = summaryStatementSubmitButtonActions;
      }
    }

    if(isSaqCard) {
      return <></>;
    }

    if(isFinishCaseCard) {
      const quizContent = !this.props.documentContext.quizUrl ? '' : `
        <div class="quiz-buttons">
          ${this.props.documentContext.quizUrl.includes('create_start_quiz') ? `
            <a class="finish-card-button" href=${this.props.documentContext.quizUrl} target="_blank" aria-label="Take the quiz now" > <span class="finish-card-quiz-text" data-url=${this.props.documentContext.quizUrl}> Take the quiz now </span> </a>
          ` : `
            <a class="finish-card-button"
              href="#"
              data-url=${this.props.documentContext.quizUrl}
              role="button"
              aria-label="Take the quiz now"
              onclick="javascript: event.preventDefault();
                                        fetch(event.target.dataset.url)
                                          .then(response => response.text())
                                          .then(html => Turbo.renderStreamMessage(html))
                                          .then(() => {
                                              document.getElementById('openQuestionContainer').classList.remove('hidden');
                                              analytics.track('open_questions', { page: window.location.href, source: 'Case Page', case_name: '${this.props.documentContext.title}', time_on_case: '${this.props.documentContext.timeOnCase}' });
                                          });"
            >
              <span class="finish-card-quiz-text" data-url=${this.props.documentContext.quizUrl}> Take the quiz now </span>
            </a>
          ` }
          ${ !this.props.documentContext.todoUrl ? '' : `
            <a href="#"
              data-url=${this.props.documentContext.todoUrl}
              role="button"
              aria-label="Save quiz to do list"
              onclick="javascript: event.preventDefault();
                                    fetch(event.target.dataset.url)
                                    .then(response => response.text())
                                    .then(html => Turbo.renderStreamMessage(html))
                                    .then(() => {
                                        document.getElementById('todo_form_container').classList.remove('hidden');
                                        analytics.track('saq_save_to_do', { page: window.location.href, source: 'Case Page', case_name: '${this.props.documentContext.title}', time_on_case: '${this.props.documentContext.timeOnCase}' });
                                    });"
              class="finish-card-todo-button"
              id="todoURLButton"
              >
              ${this.props.documentContext.todoAction}
            </a>
          `}
        </div>
        <br>
      `;

      const content = `
        <div class="finish-card">
          <div class='finish-card-start'>
            Well done! You have completed the case.
          </div>
          <div class="finish-card-layout">
            ${this.props.documentContext.quizUrl ? `
            <div class="finish-card-quiz-body">
              <div class='finish-card-body'>
                <h5 class='finish-card-heading'> Next: Check your knowledge </h5>
                <div class='finish-card-question-icon'></div>
                <p class='finish-card-content'> Take a PracticeSmart quiz with ${this.props.documentContext.quizQuestionCount} questions related to this case. </p>
                <p class='finish-card-content'> <b> Short on time? </b> Save the quiz to your to-do list for later.</p>
              </div>
              ${quizContent}
            </div>
            ` : ''}
            <div class='finish-card-summary-body'>
              <h5 class='finish-card-heading'> ACCESS CASE SUMMARY </h5>
              <div class='finish-card-summary-icon'></div>
              <p class='finish-card-content'> Download a printable Case Summary of the key teaching points covered in this case. </p>
            </div>
            <a class="finish-card-summary-button" href=${this.props.documentContext.caseSummaryUrl} target="_blank" onclick="analytics.track('view_case_summary', { page: window.location.href, source: 'Case Page', case_name: '${this.props.documentContext.title}', time_on_case: '${this.props.documentContext.timeOnCase}' });"> View Case Summary <span class="view-summary-icon"></span> </a>
          </div>
        </div>
        <div class="gen-modal hidden" id="openQuestionContainer">
          <div class="gen-modal-panel" style="padding-top: 33px; padding-left: 51px; padding-right: 63px; font-family: roboto; font-size: 16px;width: 498px;">
            <div id="quizModal"></div>
          </div>
        </div>
      `;

      // Modal content (same as original content but with a different UI)
      const modalContent = `
        <div data-controller="modal">
          <div class="gen-modal" data-modal-target="modal" id="finish_card_modal" role="dialog" aria-labelledby="finish_card_heading" aria-describedby="finish_card_body">
            <div class="gen-modal-panel">
              <div class="gen-modal-header-right">
                <button class="close-icon"aria-label="Close modal" data-action="click->modal#close">x</button>
              </div>
              <div class="finish-card">
                ${this.props.documentContext.quizUrl ? `
                <div class="finish-card-image-container">
                  <img alt="Quiztime" class="finish-card-image" aria-hidden="true" />
                </div>
                ` : ''}
                <div class="finish-card-layout">
                  ${this.props.documentContext.quizUrl ? `
                  <div class="finish-card-quiz-body">
                    <div class='finish-card-body'>
                      <h5 class='finish-card-heading'> Next: Check your knowledge </h5>
                      <p class='finish-card-content'> Take a PracticeSmart quiz with ${this.props.documentContext.quizQuestionCount} questions related to this case. </p>
                      <p class='finish-card-content'> <b> Short on time? </b> Save the quiz to your to-do list for later.</p>
                    </div>
                    ${quizContent}
                  </div>
                  ` : ''}
                  <div class='finish-card-summary-body'>
                    <h5 class='finish-card-heading'> ACCESS CASE SUMMARY </h5>
                    <p class='finish-card-content'> Download a printable Case Summary of the key teaching points covered in this case. </p>
                  </div>
                  <a class="finish-card-summary-button" href=${this.props.documentContext.caseSummaryUrl} target="_blank" aria-label="View case summary" onclick="analytics.track('view_case_summary', { page: window.location.href, source: 'Case Page', case_name: '${this.props.documentContext.title}', time_on_case: '${this.props.documentContext.timeOnCase}' });" autofocus> View case summary <span class="view-summary-icon"></span></a>
                </div>
              </div>
              <div class="gen-modal-header-right">
                <button class="close-btn" aria-label="Close modal" data-action="click->modal#close"> Close </span>
              </div>
            </div>
          </div>
        </div>
      `;

      // Combine original content and modal
      const finalContent = this.state.showModal ? `
        ${content}
        ${modalContent}
      ` : `${content}`;

      propsClone.title = 'Next Steps';
      if (propsClone.content[1]) {
        propsClone.content[1].content = finalContent;
      }
    }

    return (
      <div
        id={this.props.uid}
        data-is-node
        data-uid={this.props.uid}
        className={classes}
        style={backgroundStyleFromNode(this.props)}
        contentEditable={false}
        ref={this.sectionRef}
      >
        <DocSectionHeader {...propsClone} />
        <div className="doc-section-body">
          <DocCDQFeatures {...this.props} />
          <DocBlockNodes
            {...this.props}
            content={visibleContent}
            contentKey="content"
            independentlyEditable
            openSiblingSectionUIDs={openSiblingSectionUIDs}
            shouldHaveAddNodes={this.props.editingContext.isEditing && (this.props.displayContext.isDisplayFull || !this.props.collapsed)}
          />
          {shouldDisplayIntegrationTags &&
            <IntegrationsTags
              {...this.props.integrationInfo}
            />
          }
        </div>
      </div>
    );
  }
}
