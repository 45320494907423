import React, { Component } from 'react';

class AssessmentDashboardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preserveDates: false,
      preserveAssessment: false,
      preservePurpose: false,
      preserveStudents: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (!this.props.isOpen) return

    if (event.key === 'Escape') {
      this.props.onClose();
    } else if (event.key === 'Enter') {
      this.handleSave();
    }
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  handleSave = () => {
    const { preserveAssessment, preservePurpose, preserveStudents } = this.state;
    if (preserveAssessment && preservePurpose && preserveStudents) {
      const confirmMessage =
        "Warning: By selecting 'Assessment,' 'Purpose,' and 'Students,' you will assign the same assessment to students twice. Are you sure you want to proceed?";
      if (window.confirm(confirmMessage)) {
        this.proceedToSave();
      }
    } else {
      this.proceedToSave();
    }
  };

  proceedToSave = () => {
    const { url } = this.props;
    const { preserveDates, preserveAssessment, preservePurpose, preserveStudents } = this.state;
  
    const params = new URLSearchParams({
      preserve_dates: preserveDates,
      preserve_assessment: preserveAssessment,
      preserve_purpose: preservePurpose,
      preserve_students: preserveStudents,
    });
  
    const requestUrl = `${url}?${params.toString()}`;
    window.location.href = requestUrl;
  };

  isDateRangeValid = () => {
    const { date_range } = this.props;
    const [start, end] = date_range.split(' - ').map((date) => new Date(date));
    const now = new Date();
    return end >= now;
  };

  render() {
    const { isOpen, onClose } = this.props;
    const { preserveDates, preserveAssessment, preservePurpose, preserveStudents } = this.state;
    const assessmentName = this.props.assessment?.replace(/\s\d{4}$/, '');

    if (!isOpen) return null;

    const showAvailabilityDates = this.isDateRangeValid();

    return (
      <div className="modal-overlay">
        <div className="modal-container">
          <button className="close-button" onClick={onClose} aria-label="Close modal">
            &times;
          </button>
          <div className="modal-body">
            <div style={{fontSize:"22px", fontWeight: 700}}>
              Duplicate Assessment
            </div>
            <div style={{ padding: '10px 0px' }}>
              Choose the fields you would like to duplicate for your new assessment.
              <br />
              You will be able to edit all fields once you save the duplicate.
            </div>
            <form>
              {showAvailabilityDates && (
                <label className='modal-elements'>
                  <input
                    type="checkbox"
                    name="preserveDates"
                    checked={preserveDates}
                    onChange={this.handleCheckboxChange}
                    aria-label="Preserve availability dates"
                  />
                  <span style={{ fontWeight: 'normal', paddingRight: '5px' }}>Availability Dates: </span>
                  <span style={{ fontWeight: 'bold' }}>{this.props.date_range}</span>
                </label>
              )}
              <label className='modal-elements'>
                <input
                  type="checkbox"
                  name="preserveAssessment"
                  checked={preserveAssessment}
                  onChange={this.handleCheckboxChange}
                  aria-label="Preserve assessment"
                />
                <span style={{ fontWeight: 'normal', paddingRight: '5px' }}>Assessment: </span>
                <span style={{ fontWeight: 'bold' }}>{assessmentName}</span>
              </label>
              <label className='modal-elements'>
                <input
                  type="checkbox"
                  name="preservePurpose"
                  checked={preservePurpose}
                  onChange={this.handleCheckboxChange}
                  aria-label="Preserve purpose"
                />
                <span style={{ fontWeight: 'normal', paddingRight: '5px' }}>Purpose: </span>
                <span style={{ fontWeight: 'bold' }}>{this.props.purpose}</span>
              </label>
              <label className='modal-elements'>
                <input
                  type="checkbox"
                  name="preserveStudents"
                  checked={preserveStudents}
                  onChange={this.handleCheckboxChange}
                  aria-label="Preserve students"
                />
                <span style={{ fontWeight: 'normal', paddingRight: '5px' }}>Students: </span>
                <span style={{ fontWeight: 'bold' }}>{this.props.student_count}</span>
              </label>
            </form>
          </div>
          <button
            className="actionBtn"
            style={{ margin: "30px 30px" }}
            onClick={this.handleSave}
            aria-label="Save duplicate assessment"
          >
            Save Duplicate
          </button>
          <button
            className="aq-button-2 outline-whitebg-dark-grey"
            style={{ float: 'right', margin: "30px 30px", textTransform: 'uppercase' }}
            onClick={onClose}
            aria-label="Cancel and close modal"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

export default AssessmentDashboardModal;
