import React from "react";
import ClcBar from "./clc_bar";
import { sortImage, SortingCurricularSummary } from "./sorting_curricular_summary";
import Ibubble from "./ibubble";

export default class CurricularSummaryDiscipline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.isDefaultCollapse,
      filteredData: this.props.rows.questions_count_by_clinical_disciplines,
      sortCol: "number_of_items",
      sortDir: "asc"
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateCollapsedState);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateCollapsedState);
  }

  updateCollapsedState = () => {
    const isSmallScreen = window.innerWidth < 768;
    this.setState({ collapsed: !isSmallScreen });
  };

  handleSort = (sortCol, sortDir) => {
    const isClc = sortCol === 'ccc_hash';
    this.setState({
      sortCol,
      sortDir,
      filteredData: SortingCurricularSummary(
        this.state.filteredData,
        sortCol,
        sortDir,
        isClc,
      ),
    });
  };

  getCorrectResponses(row) {
    if (row.accuracy_fraction === 0) {
      return "N/A"
    } else {
      return `${row.accuracy_percentage}%`
    }
  }

  render() {
    return (
      <>
        <div className="performanceQuizCard focusArea">
          <div className="performanceQuizCardTitleRow filter-label-focus" tabIndex="0"
              onClick={() => {
                this.setState({ collapsed: !this.state.collapsed });
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  this.setState({ collapsed: !this.state.collapsed });
                }
              }}
            >
            <div
              className={
                this.state.collapsed
                  ? "saqResultsCardOpen"
                  : "saqResultsCardClosed"
              }
            ></div>
            <h2 className="saqResultsCardTitle1">CLINICAL DISCIPLINE</h2>
          </div>
          <table
            className="performanceQuizCardTable performanceSummary performancePointer"
            style={{ display: this.state.collapsed ? "none" : "table" }}
          >
            <thead>
              <tr>
                <th width="25%">CLINICAL DISCIPLINE</th>
                <th width="15%">
                  <div
                    onClick={() => {
                      const newSortDir =
                        this.state.sortCol === "completed_questions" && this.state.sortDir === "asc"
                          ? "desc"
                          : "asc";
                      this.handleSort("completed_questions", newSortDir);
                    }}
                  >
                    QUESTIONS COMPLETED &nbsp;
                    {sortImage(
                      this.state.sortCol,
                      "completed_questions",
                      this.handleSort
                    )}
                  </div>
                </th>
                <th width="15%">
                  <div
                    onClick={() => {
                      const newSortDir =
                        this.state.sortCol === "accuracy_percentage" && this.state.sortDir === "asc"
                          ? "desc"
                          : "asc";
                      this.handleSort("accuracy_percentage", newSortDir);
                    }}
                  >
                    AVG. ACCURACY &nbsp;
                    {sortImage(
                      this.state.sortCol,
                      "accuracy_percentage",
                      this.handleSort
                    )}
                  </div>
                </th>
                <th width="25%">
                  <div className="position-relative d-flex align-items-center gap-1" 
                    onClick={() => {
                      const newSortDir =
                        this.state.sortCol === "ccc_hash" && this.state.sortDir === "asc"
                          ? "desc"
                          : "asc";
                      this.handleSort("ccc_hash", newSortDir);
                    }}
                  >
                    <div>
                    CLINICAL LEARNING CALIBRATION &nbsp;
                    {sortImage(
                      this.state.sortCol,
                      "ccc_hash",
                      this.handleSort
                    )}
                    </div>
                    <sup className="info-bubble learning-bubble">
                      <span className="tooltip-box">
                        <Ibubble />
                      </span>
                    </sup>
                  </div>
                </th>
                <th width="20%">
                  <div
                    onClick={() => {
                      const newSortDir =
                        this.state.sortCol === "students_assessed" && this.state.sortDir === "asc"
                          ? "desc"
                          : "asc";
                      this.handleSort("students_assessed", newSortDir);
                    }}
                  >
                    STUDENT ASSESSED &nbsp;
                    {sortImage(
                      this.state.sortCol,
                      "students_assessed",
                      this.handleSort
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.filteredData.map((row, i) => (
                <tr key={`curricularSummaryTable${i}`}>
                  <td width="25%">
                    {row.name || 'N/A'}
                  </td>
                  <td width="15%">
                    {row.completed_questions}
                  </td>
                  <td width="15%">{this.getCorrectResponses(row)}</td>
                  <td width="25%" style={{ textAlign: '-webkit-center' }}>
                    <ClcBar data={row.ccc_hash} />
                  </td>
                  <td width="20%">
                    {row.students_assessed}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
