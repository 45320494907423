// src/config/clientConfig.ts
import { client } from "./sdk.gen";
import Cookies from "js-cookie";

// Get the auth token from cookies
const authToken = Cookies.get("auth_token");

// Configure the client with base URL and headers
client.setConfig({
  baseUrl: '/',
  headers: {
    Authorization: authToken ? `Bearer ${authToken}` : "",
    "Content-Type": "application/json",
  },
});

// Export the client for use in the application
export default client;
