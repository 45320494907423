// EDITOR PANELS
export const AET_ADMIN_TOOLS  = 'relier-frontend/ui/reasoningTool/AET_ADMIN_TOOLS'
export const ASSESSMENT_QUESTION_DATA  = 'relier-frontend/ui/reasoningTool/ASSESSMENT_QUESTION_DATA'
export const QUESTION_DETAILS = 'relier-frontend/ui/reasoningTool/QUESTION_DETAILS'
export const CASE_LEARNING_OBJECTIVES  = 'relier-frontend/ui/reasoningTool/CASE_LEARNING_OBJECTIVES'
export const CASE_TEACHING_POINTS  = 'relier-frontend/ui/reasoningTool/CASE_TEACHING_POINTS'
export const CASE_MAP  = 'relier-frontend/ui/reasoningTool/CASE_MAP'
export const CASE_METADATA  = 'relier-frontend/ui/reasoningTool/CASE_METADATA'
export const EDITORS_CHECKLIST  = 'relier-frontend/ui/reasoningTool/EDITORS_CHECKLIST'
export const SUMMARY_OF_EDITS  = 'relier-frontend/ui/reasoningTool/SUMMARY_OF_EDITS'
export const STUDENT_FEEDBACK  = 'relier-frontend/ui/reasoningTool/STUDENT_FEEDBACK'

// STUDENT PANELS
export const BOOKMARKS = 'relier-frontend/ui/reasoningTool/BOOKMARKS'
export const DIAGNOSES = 'relier-frontend/ui/reasoningTool/DIAGNOSES'
export const FEEDBACK = 'relier-frontend/ui/reasoningTool/FEEDBACK'
export const FINDINGS = 'relier-frontend/ui/reasoningTool/FINDINGS'
export const LABVALUES = 'relier-frontend/ui/reasoningTool/LABVALUES'
export const NOTES = 'relier-frontend/ui/reasoningTool/NOTES'

//Action Type Definitions
export const TOGGLE_PANEL = 'relier-frontend/ui/reasoningTool/TOGGLE_PANEL'
export const OPEN_PANEL = 'relier-frontend/ui/reasoningTool/OPEN_PANEL'
export const TOGGLE_PANEL_DELETING = 'relier-frontend/ui/reasoningTool/TOGGLE_PANEL_DELETING'
export const PANEL_NODE_EDIT_START = 'relier-frontend/ui/reasoningTool/PANEL_NODE_EDIT_START'
export const PANEL_NODE_EDIT_END = 'relier-frontend/ui/reasoningTool/PANEL_NODE_EDIT_END'
export const RESET = 'relier-frontend/ui/reasoningTool/RESET'

//Intial State
const initialState = {
}

//Reducer
export default function reducer(state:any = initialState, action:any = {}) { // TODO: Remove any
  switch (action.type) {
    case TOGGLE_PANEL:
      if (state.activePanel === action.payload.panelType) {
        return {...state, activePanel: undefined, isPanelDeleting: false, activeEditNodeUid: null}
      } else {
        return {...state, activePanel: action.payload.panelType, isPanelDeleting: false, activeEditNodeUid: null}
      }
    case OPEN_PANEL:
      return {...state, activePanel: action.payload.panelType, isPanelDeleting: false, activeEditNodeUid: null}
    case TOGGLE_PANEL_DELETING:
      return {...state, isPanelDeleting: !state.isPanelDeleting}
    case PANEL_NODE_EDIT_START:
      return {...state, activeEditNodeUid: action.payload.nodeUid}
    case PANEL_NODE_EDIT_END:
      if (state.activeEditNodeUid === action.payload.nodeUid) {
        return {...state, activeEditNodeUid: null}
      } else {
        return state
      }
    case RESET:
      return {...initialState}
    default:
      return state
  }
}

//Actions
export function togglePanel(panelType) {
  return {
    type: TOGGLE_PANEL,
    payload: {panelType}
  }
}

export function openPanel(panelType) {
  return {
    type: OPEN_PANEL,
    payload: {panelType}
  }
}

export function togglePanelDeleting() {
  return {
    type: TOGGLE_PANEL_DELETING,
  }
}

export function panelNodeEditStart(nodeUid: UID) {
  return {
    type: PANEL_NODE_EDIT_START,
    payload: {nodeUid}
  }
}

export function panelNodeEditEnd(nodeUid: UID) {
  return {
    type: PANEL_NODE_EDIT_END,
    payload: {nodeUid}
  }
}

export function reset() {
  return {
    type: RESET,
  }
}

export const reasoningToolActionCreators = {
  togglePanel: togglePanel,
  openPanel: openPanel,
  togglePanelDeleting: togglePanelDeleting,
  panelNodeEditStart: panelNodeEditStart,
  panelNodeEditEnd: panelNodeEditEnd,
  reset: reset,
}
