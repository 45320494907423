import React from 'react';
import ReactDOM from 'react-dom';
import GenSortTable from 'Gen/SortTable/component'

import TableDownArrowImage from "images/table-down_arrow.png";
import TableUpArrowImage from "images/table-up_arrow.png";
import CustomMultiSelect from './CustomMultiSelect';

class StudentLearningProgressTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      filteredData: this.props.rows,
      sortCol: 'status',
      sortDir: 'asc',
      assessment_type: [],
      early_assessment_date: [],
      late_assessment_date: [],
    };
  }

  sortProgresses = (sortCol, sortDir) =>{
    var data = this.state.filteredData
    if(sortCol === "progress_indicator_text_late" || sortCol === "progress_indicator_text_early"){
      data = data.filter(item => item[sortCol] !== 'N/A')
      const order = ['On Track', 'Below Target', 'Off Track'];
      if(sortDir==="asc"){
        data.sort((a, b) => {
          const aIndex = order.indexOf(a[sortCol]);
          const bIndex = order.indexOf(b[sortCol]);
          return aIndex - bIndex;
        });
      }else{
        data.sort((a, b) => {
          const aIndex = order.indexOf(a[sortCol]);
          const bIndex = order.indexOf(b[sortCol]);
          return bIndex - aIndex;
        });
      }
      data = data.concat(this.state.filteredData.filter(item => item[sortCol] === 'N/A'))
    }else {
      data.sort((a, b) => {
        let fa = a[sortCol].toLowerCase(),
          fb = b[sortCol].toLowerCase();
        if (fa < fb) {
          return sortDir == 'asc' ? -1 : 1;
        }
        if (fa > fb) {
          return sortDir == 'asc' ? 1 : -11;
        }
        return 0;
      });
    }
    return data
  }

  sortImage = (sortCol)=>{
    return this.state.sortCol == sortCol ? this.state.sortDir == 'asc' ? TableDownArrowImage : TableUpArrowImage : TableDownArrowImage
  }

  applyFilters = () => {
    const { early_assessment_date, late_assessment_date ,assessment_type, search } = this.state;
    const filteredData = this.props.rows.filter((item) => {
      const matchesEarlyAssessmentDate = early_assessment_date.length === 0 || early_assessment_date.some(date => item.date_range_for_early_assessment?.includes(date));
      const matchesLateAssessmentDate = late_assessment_date.length === 0 || late_assessment_date.some(date => item.date_range_for_late_assessment?.includes(date));
      const matchesAssessmentType = assessment_type.length === 0 || assessment_type.some(type => item.assessment_type?.includes(type));
      const matchesSearch = item.student_name.toLowerCase().includes(search.toLowerCase()) ||
        item.status.toLowerCase().includes(search.toLowerCase()) ||
        (this.props.report_type == 'multi-cohort' &&
          (
            (item.assessment_type?.toLowerCase() || '').includes(search.toLowerCase()) ||
            (item.status_for_early_assessment?.toLowerCase() || '').includes(search.toLowerCase()) ||
            (item.status_for_late_assessment?.toLowerCase() || '').includes(search.toLowerCase())
          )
        );

      return matchesEarlyAssessmentDate && matchesLateAssessmentDate && matchesAssessmentType && matchesSearch;
    });
    this.setState({ filteredData });
  }

  handleFilterChange = (filterName, values) => {
    this.setState({ [filterName]: values }, this.applyFilters);
  }

  filtersRow() {
    if (this.props.multiple_cohorts) {
      return (
        <div>
          <div className="filtersRow">
            <div className="form-inline">
              <CustomMultiSelect
                options={this.props.early_assessment_date_options}
                placeholder="Early Assessment Date"
                selectedOptions={this.state.early_assessment_date}
                onChange={(selectedOptions) => this.handleFilterChange('early_assessment_date', selectedOptions)}
              />

              <CustomMultiSelect
                options={this.props.late_assessment_date_options}
                placeholder="Late Assessment Date"
                selectedOptions={this.state.late_assessment_date}
                onChange={(selectedOptions) => this.handleFilterChange('late_assessment_date', selectedOptions)}
              />

              <CustomMultiSelect
                options={this.props.discipline_options}
                placeholder="Discipline"
                selectedOptions={this.state.assessment_type}
                onChange={(selectedOptions) => this.handleFilterChange('assessment_type', selectedOptions)}
              />
            </div>
          </div>

          <div className='filtersRow' style={{ justifyContent: 'center', marginBottom: '-10px' }}>
            {this.state.assessment_type.length > 0 && this.state.assessment_type.map((selectedAssessmentType, index) => (
              <p key={index} onClick={() => {
                const updatedAssessmentType = this.state.assessment_type.filter(at => at !== selectedAssessmentType);
                this.handleFilterChange('assessment_type', updatedAssessmentType);
              }} className='filterSelectedVal'>{selectedAssessmentType}&nbsp;&nbsp;&nbsp; X</p>
            ))}

            {this.state.early_assessment_date.length > 0 && this.state.early_assessment_date.map((selectedEarlyAssessmentDate, index) => (
              <p key={index} onClick={() => {
                const updatedEarlyAssessmentDate = this.state.early_assessment_date.filter(adt => adt !== selectedEarlyAssessmentDate);
                this.handleFilterChange('early_assessment_date', updatedEarlyAssessmentDate);
              }} className='filterSelectedVal'>{selectedEarlyAssessmentDate}&nbsp;&nbsp;&nbsp; X</p>
            ))}

            {this.state.late_assessment_date.length > 0 && this.state.late_assessment_date.map((selectedLateAssessmentDate, index) => (
              <p key={index} onClick={() => {
                const updatedLateAssessmentDate = this.state.late_assessment_date.filter(ad => ad !== selectedLateAssessmentDate);
                this.handleFilterChange('late_assessment_date', updatedLateAssessmentDate);
              }} className='filterSelectedVal'>{selectedLateAssessmentDate}&nbsp;&nbsp;&nbsp; X</p>
            ))}
          </div>
        </div>
      )
    }
  }


  render() {
    return <>
      <div className="detailsSection">
        <div className="col-md-12">
          <div className="sectionTitleRow">
            <div className="educator-detail-heading">
              <h2 className="reportTopSectionTitle">{this.props.purpose == "Early" ? "Early -" : ""} Individual Student Learning Progress</h2>
              {(() => {
                if (this.props.report_type == 'multi-cohort' || this.props.purpose == 'Late') {
                  return (<div className="info-bubble">
                    <span className="tooltip-box small after above-center tab-1">
                      Assessment details are displayed in early/late pairs. If both early and late data are available, the LPI displays for both with all other fields displaying for the late assessment. If there is no late assessment, the late LPI displays as N/A and all other details are matched with the early assessment.
                    </span>
                  </div>)
                }
              })()}
            </div>
            <div className="form-group" style={{width: '400px'}}>
              <input type="text" name="search" placeholder='Search:' className="form-control" onChange={e => this.setState({ search: e.target.value }, this.applyFilters)} />
            </div>
          </div>

          {this.filtersRow()}
          {(() => {
            if (this.props.report_type == 'multi-cohort') {
              return (
                <table className="detailsTable">
                  <thead>
                    <tr>
                      <th
                        key="student-name"
                        rowSpan="2"
                        className="bottomSectionGraphLegendText progress-table-head sortable"
                        width="250"
                        onClick={() => this.setState({
                          sortCol: 'student_name',
                          sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                          filteredData: this.sortProgresses('student_name', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                          })
                        }
                      >Student Name<img src={this.sortImage('student_name')} height='20' width='20' /></th>
                      <th key="learning-progress" colSpan="2"
                        className="bottomSectionGraphLegendText progress-table-head sortable"
                        width="300"
                        onClick={() => {
                          var sortColName= 'progress_indicator_text_late'
                          this.setState({
                          sortCol: sortColName,
                          sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                          filteredData: this.sortProgresses(sortColName, this.state.sortDir == 'asc' ? 'desc' : 'asc')
                          })}
                        }
                      >
                        Learning Progress
                        <img src={this.sortImage('progress_indicator_text_late')} height='20' width='20' />
                        <div className="info-bubble" style={{marginLeft: '65%', marginTop: '-25px'}}>
                          <span className="tooltip-box mid after">
                            Sort by late LPI
                          </span>
                        </div>
                      </th>
                      <th width="200" key="assessment-types" rowSpan="2" className="bottomSectionGraphLegendText progress-table-head">Assessment Type</th>
                      <th key="exam-status" colSpan="2" className="bottomSectionGraphLegendText progress-table-head" style={{textAlign: 'center' }} >Assessment Status</th>
                      <th key="assessment-dates" colSpan="2" className="bottomSectionGraphLegendText progress-table-head" style={{textAlign: 'center' }}>Assessment Dates</th>
                    </tr>
                    <tr>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Early</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Late</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Early</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Late</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Early</th>
                      <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Late</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.filteredData.map((row, i) => {
                      return <tr key={`studentLearningProgressTable${i}`}>
                        <td>
                          {row.student_name}
                          <div><a target="_blank" href={row.student_case_log_path}>Student Case <br></br> Log Report</a></div>
                        </td>
                        <td>
                          <div className={`progressIndicatorBox ${row.progress_indicator_color_early}`}><div className="progressIndicatorText">{row.progress_indicator_text_early}</div></div>
                          {row.early_rostering_progress_url && this.props.ind_result_faculty_feature && (
                            <div className="mt-1"><a target="_blank" href={row.early_rostering_progress_url} onClick={() => analytics.track("student_detail_report_access", { page: window.location.href, source: 'Calibrate Reports', student_name: row.student_full_name ,student_email: row.email, assessment_name: row.assessment_type, lpi: row.progress_indicator_text_early })}>Results</a></div>
                          )}
                        </td>
                        <td>
                          <div className={`progressIndicatorBox ${row.progress_indicator_color_late}`}><div className="progressIndicatorText">{row.progress_indicator_text_late}</div></div>
                          {row.late_rostering_progress_url && this.props.ind_result_faculty_feature && (
                            <div className="mt-1"><a target="_blank" href={row.late_rostering_progress_url} onClick={() => analytics.track("student_detail_report_access", { page: window.location.href, source: 'Calibrate Reports', student_name: row.student_full_name,student_email: row.email, assessment_name: row.assessment_type, lpi: row.progress_indicator_text_late })} >Results</a></div>
                          )}
                        </td>
                        <td>{row.assessment_type}</td>
                        <td>{row.status_for_early_assessment || "N/A"}</td>
                        <td>{row.status_for_late_assessment || "N/A"}</td>
                        <td>{row.date_range_for_early_assessment || "N/A"}</td>
                        <td>{row.date_range_for_late_assessment || "N/A"}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              );
            } else {
              return (
                <table className="detailsTable">
                  <thead>
                    <tr>
                      <th
                        key="student-name"
                        rowSpan="2"
                        className="bottomSectionGraphLegendText progress-table-head sortable"
                        width="250"
                        onClick={() => this.setState({
                          sortCol: 'student_name',
                          sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                          filteredData: this.sortProgresses('student_name', this.state.sortDir == 'asc' ? 'desc' : 'asc')
                          })
                        }
                      >Student Name<img src={this.sortImage('student_name')} height='20' width='20' /></th>
                      <th key="learning-progress" colSpan="2"
                        className="bottomSectionGraphLegendText progress-table-head sortable"
                        width="300"
                        onClick={() => {
                          var sortColName= (this.props.purpose === 'Late') ? 'progress_indicator_text_late' : 'progress_indicator_text_early'
                          this.setState({
                          sortCol: sortColName,
                          sortDir: this.state.sortDir == 'asc' ? 'desc' : 'asc',
                          filteredData: this.sortProgresses(sortColName, this.state.sortDir == 'asc' ? 'desc' : 'asc')
                          })}
                        }
                      >
                        Learning Progress
                        <img src={this.sortImage((this.props.purpose === 'Late') ? 'progress_indicator_text_late' : 'progress_indicator_text_early')} height='20' width='20' />
                        {(() => {
                          if (this.props.purpose === 'Late') {
                            return (
                              <div className="info-bubble" style={{marginLeft: '65%', marginTop: '-25px'}}>
                                <span className="tooltip-box mid after">
                                  Sort by late LPI
                                </span>
                              </div>
                            )
                          }
                        })()}

                      </th>
                      <th className="bottomSectionGraphLegendText progress-table-head" rowSpan="2" width="300">
                        <span>
                          Case Completion
                        </span>
                        <div className="info-bubble" style={{marginLeft: '140px', marginTop: '-22px'}}>
                          <span className="tooltip-box mid after" >
                            Case completion for all {this.props.discipline} cases at the time of assessment
                          </span>
                        </div>
                      </th>
                      <th key="exam-status" className="bottomSectionGraphLegendText progress-table-head" rowSpan="2">Exam Status</th>
                    </tr>
                    {(() => {
                      if (this.props.purpose === 'Late') {
                        return (
                          <tr>
                            <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Early</th>
                            <th className="bottomSectionGraphLegendText small" style={{textAlign: 'left', fontSize: '12px'}}>Late</th>
                          </tr>
                        )
                      }
                    })()}
                  </thead>
                  <tbody>
                    {this.state.filteredData.map((row, i) => {
                      return <tr key={`studentLearningProgressTable${i}`}>
                        <td>{row.student_name}</td>
                        <td>
                          <div className={`progressIndicatorBox ${row.progress_indicator_color_early}`}><div className="progressIndicatorText">{row.progress_indicator_text_early}</div></div>
                          {row.early_rostering_progress_url && this.props.ind_result_faculty_feature && (
                            <div className="mt-1"><a target="_blank" href={row.early_rostering_progress_url} onClick={() => analytics.track("student_detail_report_access", { page: window.location.href, source: 'Calibrate Reports', student_name: row.student_full_name,student_email: row.email, assessment_name: row.assessment_type, lpi: row.progress_indicator_text_early })}>Results</a></div>
                          )}
                        </td>
                        <td>
                          <div className={`progressIndicatorBox ${row.progress_indicator_color_late}`}><div className="progressIndicatorText">{row.progress_indicator_text_late}</div></div>
                          {row.late_rostering_progress_url && this.props.ind_result_faculty_feature && (
                            <div className="mt-1"><a target="_blank" href={row.late_rostering_progress_url} onClick={() => analytics.track("student_detail_report_access", { page: window.location.href, source: 'Calibrate Reports', student_name: row.student_full_name,student_email: row.email, assessment_name: row.assessment_type, lpi: row.progress_indicator_text_late })}>Results</a></div>
                          )}
                        </td>
                        <td>
                          <div className="row">
                            <div className="col-md-12">
                              <span>{row.case_completion_fraction}</span>
                              <div className="mt-1"><a target="_blank" href={row.student_case_log_path} >Student Case Log Report</a></div>
                            </div>
                          </div>
                        </td>
                        <td>{row.status}</td>
                      </tr>
                    })}
                  </tbody>
                </table>
              );
            }
          })()}
        </div>
      </div>
    </>
  }
}

export default StudentLearningProgressTable;
