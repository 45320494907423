import * as React from "react";
import * as classNames from "classnames";

import * as pluralize from "pluralize";

import ReasoningToolPanelDeleteCell from "./Panel/DeleteCell";

import generateUUID from "../../../helpers/generateUUID";

interface ReasoningToolPanelProps {
  title: string;
  infoBox?: React.ReactNode;
  isDeleting?: boolean;
  toggleDeleting?: () => void;
  headerButton?: React.ReactNode;
  isAddable?: boolean;
  wide?: boolean;
  children: React.ReactNode;
  panelNodeEditStart?: (uid: string) => void;
  addNode?: (uid: string, data?: Record<string, any>) => void;
  deleteNode?: (uid: string) => void;
  displayContext?: DisplayContext;
};

export default function ReasoningToolPanel({
  title = "",
  infoBox = null,
  isDeleting = false,
  toggleDeleting,
  headerButton = null,
  isAddable = false,
  wide = false,
  children,
  panelNodeEditStart,
  addNode,
  deleteNode,
  displayContext,
}: ReasoningToolPanelProps) {

  let wrappedChildren = children;
  if (isDeleting) {
    wrappedChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return (
          <ReasoningToolPanelDeleteCell
            key={child.props.uid}
            deleteNode={() => {
              if (displayContext) {
                displayContext.setFocusedBookmarkUid(null);
              }
              if (deleteNode) {
                deleteNode(child.props.uid);
              }
            }}
          >
            {child}
          </ReasoningToolPanelDeleteCell>
        );
      } else {
        return child;
      }
    });
  }

  return (
    <div className={classNames("reasoning-tool-panel", (wide ? 'wide' : 'small'))}>
      <div className="reasoning-tool-panel-header">
        <div className="reasoning-tool-panel-header-left">
          <h1>{title}</h1>
          {toggleDeleting && (
            <div
              className={classNames("reasoning-tool-panel-header-delete-button", {active: isDeleting})}
              onClick={toggleDeleting}
            >
              <i className="icon-lg icon-edit"></i>
            </div>
          )}
          {headerButton}
        </div>
        <div className="reasoning-tool-panel-header-right">
        </div>
      </div>
      {infoBox && <div className="reasoning-tool-panel-header">
        <p><i>{infoBox}</i></p>
      </div>}
      <div>
        {wrappedChildren}
      </div>
      {isAddable && <div className="reasoning-tool-add-button" key="button" onClick={() => {
        if (addNode && panelNodeEditStart) {
          const newUid = generateUUID();
          addNode(newUid);
          panelNodeEditStart(newUid);
        }
      }}>
          <span><i className="icon-open-box"></i> New {pluralize.singular(title)}...</span>
      </div>}
    </div>
  );
}
