import * as React from "react";
import * as R from "ramda";

interface DocContentMappingAssessmentMapProps {
  assessmentMappingToolPermission?: boolean;
  assessmentTitle: string;
  currentLearningObjectiveReusableObjectId: number;
  learningObjectiveReusableObjects: Array<LearningObjectiveReusableObject>;
  proposedLearningObjectiveReusableObjectId: number;
  setLearningObjectiveReusableObjectId: (id: number) => void;
  usedAvailableForm: boolean;
};

interface DocContentMappingAssessmentMapState {
  resetValue: boolean;
  selectedLearningObjectiveId: number | string; //TODO: Why does this need a string
  selectedReusableObjectId: number | string; //TODO: Why does this need a string
};

export default class DocContentMappingAssessmentMap extends React.Component<DocContentMappingAssessmentMapProps, DocContentMappingAssessmentMapState> {
  constructor(props: DocContentMappingAssessmentMapProps) {
    super(props);

    this.state = {
      selectedLearningObjectiveId: (this.props.proposedLearningObjectiveReusableObjectId && this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId)) ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId).learning_objective.id : "",
      selectedReusableObjectId: (this.props.proposedLearningObjectiveReusableObjectId && this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId)) ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId).reusable_object.id : "",
      resetValue: !(this.props.proposedLearningObjectiveReusableObjectId)
    };
  };

  handleLearningObjectiveChange(event: React.ChangeEvent<HTMLSelectElement>) {
    let selectedLearningObjectiveId
    if(event.target.value!=="") {
      selectedLearningObjectiveId = parseInt(event.target.value);
    }else{
      selectedLearningObjectiveId=""
    }
    this.setState({selectedLearningObjectiveId: selectedLearningObjectiveId})
  };

  handleReusableObjectChange(event:  React.ChangeEvent<HTMLSelectElement>) {
    let selectedReusableObjectId
    if(event.target.value!=="") {
      selectedReusableObjectId = parseInt(event.target.value);
    }else{
      selectedReusableObjectId=""
    }
    this.setState({selectedReusableObjectId: selectedReusableObjectId })
  };

  clickSaveButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const hasSelectedLearningObjective = this.state.selectedLearningObjectiveId !== "";
    const hasSelectedReusableObject = this.state.selectedReusableObjectId !== "";

    if(!hasSelectedLearningObjective && !hasSelectedReusableObject){
      alert("Please select Mapping")
    }else if(hasSelectedLearningObjective && !hasSelectedReusableObject){
      alert("Please select Teaching Point")
    } else if(hasSelectedReusableObject && !hasSelectedLearningObjective){
      alert("Please select Learning Objective")
    } else if((hasSelectedLearningObjective && hasSelectedReusableObject)){
      let text=""
      if(hasSelectedLearningObjective && hasSelectedReusableObject) {
        text="When published, the selected content mappings for this Assessment Question will be available throughout the Aquifer curriculum. Click OK to proceed or Cancel to discard.";
      }else{
        text="Do you want to save this incomplete map? You will not be able to publish until mapping is complete. Click OK to save the map or Cancel to continue editing."
      }
      if (confirm(text)) {
        this.props.learningObjectiveReusableObjects.forEach((learningObjectiveReusableObject) => {
          if (learningObjectiveReusableObject.learning_objective.id === this.state.selectedLearningObjectiveId && learningObjectiveReusableObject.reusable_object.id === this.state.selectedReusableObjectId) {
            this.props.setLearningObjectiveReusableObjectId(learningObjectiveReusableObject.id)
          }
        })
        this.setState({resetValue: false })
      }
    }
  };

  clickResetButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    let text="You are editing the mapping to this Assessment Question. This could have additional curriculum mapping implications. Click Continue to proceed or Cancel to discard this change."
    if (confirm(text)) {
      this.setState({
        resetValue: true,
        selectedLearningObjectiveId: "",
        selectedReusableObjectId: "",
      })
    }
  }

  clickRestoreButton(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.props.setLearningObjectiveReusableObjectId(this.props.currentLearningObjectiveReusableObjectId)
    const currentLearningObjectiveReusableObject = this.props.currentLearningObjectiveReusableObjectId && this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.currentLearningObjectiveReusableObjectId)
    this.setState({
      selectedLearningObjectiveId: currentLearningObjectiveReusableObject ? currentLearningObjectiveReusableObject.learning_objective.id : "",
      selectedReusableObjectId: currentLearningObjectiveReusableObject ? currentLearningObjectiveReusableObject.reusable_object.id : "",
    })
  }

  render() {
    let learningObjectiveOptions = this.props.learningObjectiveReusableObjects
      .filter((learningObjectiveReusableObject) => !this.state.selectedReusableObjectId || this.state.selectedReusableObjectId===learningObjectiveReusableObject.reusable_object.id )
      .map((learningObjectiveReusableObject) => learningObjectiveReusableObject.learning_objective)

    learningObjectiveOptions = R.uniqBy((lo) => lo.id, learningObjectiveOptions)

    let reusableObjectOptions = this.props.learningObjectiveReusableObjects
      .filter((learningObjectiveReusableObject) => !this.state.selectedLearningObjectiveId || this.state.selectedLearningObjectiveId===learningObjectiveReusableObject.learning_objective.id )
      .map((learningObjectiveReusableObject) => learningObjectiveReusableObject.reusable_object)

    reusableObjectOptions = R.uniqBy((ro) => ro.id, reusableObjectOptions)

    let learningObjectColumn
    let teachingPointColumn

    let learningObjectColumnValue = this.props.currentLearningObjectiveReusableObjectId ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.currentLearningObjectiveReusableObjectId).learning_objective.name : ""
    let teachingPointColumnValue = this.props.currentLearningObjectiveReusableObjectId ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.currentLearningObjectiveReusableObjectId).reusable_object.content_title : ""

    let learningObjectiveSelect = <select onChange={this.handleLearningObjectiveChange.bind(this)} value={this.state.selectedLearningObjectiveId} disabled={!this.props.assessmentMappingToolPermission}>
      <option value="">Select</option>
      {
        learningObjectiveOptions.map((learningObjective) =>
            <option key={learningObjective.id}
                    value={learningObjective.id}>{learningObjective.name}
            </option>
        )
      }
    </select>

    let teachingPointSelect = <select onChange={this.handleReusableObjectChange.bind(this)} value={this.state.selectedReusableObjectId} disabled={!this.props.assessmentMappingToolPermission}>
      <option value="">Select</option>
      {
        reusableObjectOptions.map((reusableObject) =>
            <option key={reusableObject.id}
                    value={reusableObject.id}>{reusableObject.content_title}
            </option>
        )
      }
    </select>

    if(!this.state.resetValue) {
      const learningObjectiveReusableObjects: Array<any> = this.props.learningObjectiveReusableObjects; // TODO: Narrow down any

      if (this.props.currentLearningObjectiveReusableObjectId && R.find(R.propEq('id', this.props.currentLearningObjectiveReusableObjectId), learningObjectiveReusableObjects).learning_objective.id === R.find(R.propEq('id', this.props.proposedLearningObjectiveReusableObjectId), learningObjectiveReusableObjects).learning_objective.id) {
        learningObjectColumn = <div>{learningObjectColumnValue}</div>
      }else{
        let proposedLearningObjectColumnValue = (this.props.proposedLearningObjectiveReusableObjectId && this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId)) ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId).learning_objective.name : ""
        if(proposedLearningObjectColumnValue==""){
          learningObjectColumn=<div>{learningObjectiveSelect}</div>
        }else {
          learningObjectColumn = <div>
            <div style={{textDecoration: "line-through"}}>{learningObjectColumnValue}</div>
            <div style={{color: "red"}}>{proposedLearningObjectColumnValue}</div>
          </div>
        }
      }

      if (this.props.currentLearningObjectiveReusableObjectId && R.find(R.propEq('id', this.props.currentLearningObjectiveReusableObjectId), learningObjectiveReusableObjects).reusable_object.id === R.find(R.propEq('id', this.props.proposedLearningObjectiveReusableObjectId), learningObjectiveReusableObjects).reusable_object.id) {
        teachingPointColumn = <div>{teachingPointColumnValue}</div>
      }else{
        let proposedTeachingPointColumnValue = (this.props.proposedLearningObjectiveReusableObjectId && this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId)) ? this.props.learningObjectiveReusableObjects.find(element => element.id === this.props.proposedLearningObjectiveReusableObjectId).reusable_object.content_title : ""
        if(proposedTeachingPointColumnValue=="") {
          teachingPointColumn = <div>{teachingPointSelect}</div>
        }else {
          teachingPointColumn = <div>
            <div style={{textDecoration: "line-through"}}>{teachingPointColumnValue}</div>
            <div style={{color: "red"}}>{proposedTeachingPointColumnValue}</div>
          </div>
        }
      }
    }else{
      learningObjectColumn = <div>
        <div style={{textDecoration: "line-through"}}>{learningObjectColumnValue}</div>
          {learningObjectiveSelect}
      </div>

      teachingPointColumn = <div>
        <div style={{textDecoration: "line-through"}}>{teachingPointColumnValue}</div>
          {teachingPointSelect}
      </div>
    }

    let displayResetRestoreButton
    if(this.props.assessmentMappingToolPermission && !this.props.usedAvailableForm) {
      displayResetRestoreButton = this.props.currentLearningObjectiveReusableObjectId === this.props.proposedLearningObjectiveReusableObjectId ?
          <button className='aq-button' onClick={this.clickResetButton.bind(this)}>Edit</button> :
          <button className='aq-button' onClick={this.clickRestoreButton.bind(this)}>Restore</button>
    }

    let displaySaveButton
    if(this.props.assessmentMappingToolPermission && !this.props.usedAvailableForm) {
      displaySaveButton = <button className='aq-button' onClick={this.clickSaveButton.bind(this)}>Save</button>
    }

    let usedAvailableFormIcon
    if(this.props.usedAvailableForm){
      usedAvailableFormIcon = <i className="fas fa-file-alt"></i>
    }

    return (
      <div>
        <h5 style={{ backgroundColor: "#0095C9", padding: '5px' }}>Assessment - LOTP Map</h5>
        <div>
          <br/>
          <p style={{ fontWeight: "bold" }}>
            The table below displays the Learning Objective and Teaching Point mapped to this Assessment Question
          </p>
          <table className="aq-mapping-table">
            <tbody>
              <tr>
                <th className="aq-mapping-table-header">Assessment Question</th>
                <th className="aq-mapping-table-header">Map to Learning Objective</th>
                <th className="aq-mapping-table-header">Map to Teaching Point</th>
              </tr>
              <tr style={{ backgroundColor: "white" }}>
                <td className="aq-mapping-table-assessment-title">{this.props.assessmentTitle} {usedAvailableFormIcon}</td>
                <td className="aq-mapping-table-row">
                  <div style={{textAlign: 'center'}}>
                    {learningObjectColumn}
                  </div>
                </td>
                <td className="aq-mapping-table-row">
                  <div style={{textAlign: 'center'}}>
                    {teachingPointColumn}
                  </div>
                </td>
                <td>
                  <div style={{textAlign: 'center'}}>
                    {displayResetRestoreButton}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{textAlign: 'center'}}>
            {displaySaveButton}
          </div>
          <br/>
        </div>
      </div>
    )
  }
}
