import * as React from "react";

import * as classNames from "classnames";

import { newSetKeyValueChange } from "../../../helpers/Doc/changes";

import ReasoningToolPanel from "./Panel";
import Select from 'react-select';

interface ReasoningToolAssessmentQuestionDataPanelProps {
  editingContext: EditingContextInEditor;
  editingContextExtra: EditingContextExtra;
  cci_status: string;
};

interface ReasoningToolAssessmentQuestionDataPanelState {
  label?: string;
  clinical_location: string;
  final_diagnosis: string;
  patient_age: string;
  system: string
  clinical_focus: string
  current_use: string
  clinical_disciplines: Array<string>
  clinical_excellence: Array<string>
};

export default class ReasoningToolAssessmentQuestionDataPanel extends React.Component<ReasoningToolAssessmentQuestionDataPanelProps, ReasoningToolAssessmentQuestionDataPanelState> {
  constructor(props: ReasoningToolAssessmentQuestionDataPanelProps) {
    super(props);

    this.state = {
      clinical_location: this.props.editingContextExtra.assessmentQuestion.clinical_location,
      final_diagnosis: this.props.editingContextExtra.assessmentQuestion.final_diagnosis,
      patient_age: this.props.editingContextExtra.assessmentQuestion.patient_age,
      clinical_disciplines: this.props.editingContextExtra.assessmentQuestion.clinical_disciplines,
      clinical_excellence: this.props.editingContextExtra.assessmentQuestion.clinical_excellence,
      system: this.props.editingContextExtra.assessmentQuestion.system,
      clinical_focus: this.props.editingContextExtra.assessmentQuestion.clinical_focus,
      current_use: this.props.editingContextExtra.assessmentQuestion.current_use,
    };
  }

  save(event) {
    event.preventDefault();
    const questionData = {
      ...this.props.editingContextExtra.assessmentQuestion,
      id: this.props.editingContextExtra.assessmentQuestion.id,
      clinical_location: this.state.clinical_location,
      final_diagnosis: this.state.final_diagnosis,
      patient_age: this.state.patient_age,
      system: this.state.system,
      clinical_focus: this.state.clinical_focus,
      current_use: this.state.current_use,
      clinical_disciplines: this.state.clinical_disciplines || [],
      clinical_excellence: this.state.clinical_excellence || [],
    }
    this.props.editingContextExtra.setAssessmentQuestionData(questionData)
  }

  generateSelectOptions(options) {
    return options.map((optionName) => {
      return {value: optionName, label: optionName};
    })
  }

  render() {
    const {
      editingContext,
      editingContextExtra,
    } = this.props;

    const assessmentQuestion = editingContextExtra.assessmentQuestion;
    const assessmentQuestionStatistics = editingContextExtra.assessmentQuestionStatistics;
    const selectedAssessmentQuestionStatistic = assessmentQuestionStatistics.find((stat) => stat.label === this.state.label) || assessmentQuestionStatistics[0];
    const questionData = editingContextExtra.assessmentQuestionEditingData;
    const clinicalLocationOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionClinicalLocationOptions);
    const finalDiagnosisOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionFinalDiagnosisOptions);
    const patientAgeOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionPatientAgeOptions);
    const systemOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionSystemOptions);
    const clinicalFocusOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionClinicalFocusOptions);
    const questionUseOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionQuestionUseOptions);
    const clinicalDisciplineOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionClinicalDisciplineOptions);
    const clinicalExcellenceOptions = this.generateSelectOptions(editingContextExtra.assessmentQuestionClinicalExcellenceOptions);

    return (
      <ReasoningToolPanel
        key="ASSESSMENT QUESTION DATA"
        title="ASSESSMENT QUESTION DATA"
      >
        <h5>Assessment Question Meta Data</h5>

        <div>
        <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Question Use:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                isMulti={false}
                isClearable={true}
                options={questionUseOptions}
                defaultValue={this.state.current_use && questionUseOptions.filter(option=> this.state.current_use == option["value"])}
                onChange={ (option) => { this.setState({current_use: option && option.value}); } }
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
              />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Clinical Location:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                isMulti={false}
                isClearable={true}
                options={clinicalLocationOptions}
                defaultValue={this.state.clinical_location && clinicalLocationOptions.filter(option=> this.state.clinical_location == option["value"])}
                onChange={ (option) => { this.setState({clinical_location: option && option.value}); } }
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
              />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Final Diagnosis:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                  isMulti={false}
                  isClearable={true}
                  options={finalDiagnosisOptions}
                  defaultValue={this.state.final_diagnosis && finalDiagnosisOptions.filter(option=> this.state.final_diagnosis == option["value"])}
                  onChange={ (option) => { this.setState({final_diagnosis: option && option.value}); } }
                  menuPortalTarget={document.body}
                  styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 })
                  }}
                />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Patient Age:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                    isMulti={false}
                    isClearable={true}
                    options={patientAgeOptions}
                    defaultValue={this.state.patient_age && patientAgeOptions.filter(option=> this.state.patient_age == option["value"])}
                    onChange={ (option) => { this.setState({patient_age: option && option.value}); } }
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                  />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">System:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                    isMulti={false}
                    isClearable={true}
                    options={systemOptions}
                    defaultValue={this.state.system && systemOptions.filter(option=> this.state.system == option["value"])}
                    onChange={ (option) => { this.setState({system: option && option.value}); } }
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                  />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Clinical Focus:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                    isMulti={false}
                    isClearable={true}
                    options={clinicalFocusOptions}
                    defaultValue={this.state.clinical_focus && clinicalFocusOptions.filter(option=> this.state.clinical_focus == option["value"])}
                    onChange={ (option) => { this.setState({clinical_focus: option && option.value}); } }
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 })
                    }}
                  />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Clinical Discipline:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                isMulti={true}
                options={clinicalDisciplineOptions}
                defaultValue={this.state.clinical_disciplines && clinicalDisciplineOptions.filter(option=> this.state.clinical_disciplines.includes(option["value"]))}
                onChange={ (options) => { this.setState({clinical_disciplines: options && options.map((option) => option.value) }) }}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
              />
            </span>
          </div>


          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Clinical Excellence:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <Select
                isMulti={true}
                options={clinicalExcellenceOptions}
                defaultValue={this.state.clinical_excellence && clinicalExcellenceOptions.filter(option=> this.state.clinical_excellence.includes(option["value"]))}
                onChange={ (options) => { this.setState({clinical_excellence: options && options.map((option) => option.value) }) }}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 })
                }}
              />
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">
              <button className='aq-button'onClick={this.save.bind(this)} >Save</button>
            </span>
          </div>
        </div>

        <h5>Assessment Question Data And Statuses</h5>

        <div>
          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Item Decisions</span>
            <div className={classNames("reasoning-tool-stat-value reasoning-tool-item-decision-input", {changed: this.props.cci_status &&  this.props.cci_status !== assessmentQuestion.cci_status})}>
              <select value={editingContextExtra.cci_status} onChange={(e) => {
                editingContext.addChange(newSetKeyValueChange(editingContextExtra.rootUid, "cci_status", e.target.value));
              }}>
                {editingContextExtra.cciStatusOptions.map((cciStatus) => <option key={cciStatus.key} value={cciStatus.key}>{cciStatus.displayName}</option>)}
              </select>
            </div>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Academic Year for this Data:</span>
            <span className="reasoning-tool-stat-value reasoning-tool-item-decision-input">
              <select value={selectedAssessmentQuestionStatistic?.label} disabled={false} onChange={(e) => {this.setState({label: e.target.value});}}>
                {assessmentQuestionStatistics.map((statistic) => <option key={statistic.label} value={statistic.label}>{statistic.label}</option>)}
              </select>
            </span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label"># Administrations:</span>
            <span className="reasoning-tool-stat-value">{selectedAssessmentQuestionStatistic?.administrations_count}</span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Difficulty (pdiff):</span>
            <span className="reasoning-tool-stat-value">{selectedAssessmentQuestionStatistic?.difficulty}</span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Discrimination (rpb):</span>
            <span className="reasoning-tool-stat-value">{selectedAssessmentQuestionStatistic?.discrimination}</span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Item Validity Status:</span>
            <span className="reasoning-tool-stat-value">{editingContextExtra.assessmentStatus}</span>
          </div>

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Used on Assessment?</span>
            <span className="reasoning-tool-stat-value">{questionData && (questionData?.delivered ? "Yes" : "No")}</span>
          </div>
        </div>

        <hr />

        <div>
          <h5>Answer Option Admin Data</h5>

          {[...Array(5).keys()].map((index) => {
            const capitalLetter = String.fromCharCode("A".charCodeAt(0) + index);
            const lowerLetter = String.fromCharCode("a".charCodeAt(0) + index);
            const correct = index === assessmentQuestion?.content?.correct_answer_index;

            return (
              <div key={index} className={classNames("reasoning-tool-stat", {correct: correct})}>
                <span className="reasoning-tool-stat-label">Admin {capitalLetter}:</span>
                <span className="reasoning-tool-stat-value">{selectedAssessmentQuestionStatistic && selectedAssessmentQuestionStatistic[`choice_${lowerLetter}_count`]}</span>
                {correct && <span className="reasoning-tool-stat-comment">Correct!</span>}
              </div>
            );
          })}

          <div className="reasoning-tool-stat">
            <span className="reasoning-tool-stat-label">Admin Omit:</span>
            <span className="reasoning-tool-stat-value">{selectedAssessmentQuestionStatistic?.skips_count}</span>
          </div>
        </div>
        <hr />
      </ReasoningToolPanel>
    );
  }
}
