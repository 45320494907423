import * as React from "react";
import { useEffect, useState } from "react";
import client from "../../../../api/client";
import { getAdminAssessmentStatistics } from "../../../../api/sdk.gen";

const StatisticsTab = ({ questionId }) => {
  const [statistics, setStatistics] = useState([]);

  const fetchStatistics = async () => {
    const resp = await getAdminAssessmentStatistics({
      query: { assessment_question_id: questionId, per: 1000 },
    });
    setStatistics(resp.data.data);
  };

  useEffect(() => {
    fetchStatistics();
  }, [questionId]);

  const headers = [
    "ID",
    "Assessment Question ID",
    "Label",
    "Choice A Count",
    "Choice B Count",
    "Choice C Count",
    "Choice D Count",
    "Choice E Count",
    "Choice F Count",
    "Skips Count",
    "Administrations Count",
    "Difficulty",
    "Discrimination",
    "Avg Certainty Rating",
    "Created At",
    "Updated At",
  ];

  const renderTableHead = () => (
    <tr>
      {headers.map((header) => (
        <th key={header}>{header}</th>
      ))}
    </tr>
  );

  const renderTableRows = () => {
    if (statistics.length === 0) {
      return (
        <tr>
          <td colSpan={headers.length} style={{ textAlign: "center" }}>
            There are no records
          </td>
        </tr>
      );
    }

    return statistics.map((stat) => (
      <tr key={stat.id}>
        <td>{stat.id}</td>
        <td>{stat.assessment_question_id}</td>
        <td>{stat.label}</td>
        <td>{stat.choice_a_count}</td>
        <td>{stat.choice_b_count}</td>
        <td>{stat.choice_c_count}</td>
        <td>{stat.choice_d_count}</td>
        <td>{stat.choice_e_count}</td>
        <td>{stat.choice_f_count}</td>
        <td>{stat.skips_count}</td>
        <td>{stat.administrations_count}</td>
        <td>{stat.difficulty}</td>
        <td>{stat.discrimination}</td>
        <td>{stat.average_certainty_rating}</td>
        <td>{new Date(stat.created_at).toLocaleString()}</td>
        <td>{new Date(stat.updated_at).toLocaleString()}</td>
      </tr>
    ));
  };

  return (
    <div className="table-container">
      <table className="table-with-fixed-columns" border={1}>
        <thead>{renderTableHead()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
    </div>
  );
};

export default StatisticsTab;
