import Swal from 'sweetalert2';

const MySwal = Swal.mixin({
  customClass: {
    confirmButton: 'aq-button-2 medium with-margin',
    cancelButton: 'aq-button-2 medium with-margin outline black',
    closeButton: 'aq-button-2 medium with-margin outline black',
    denyButton: 'aq-button-2 medium with-margin outline black',
  },
  buttonsStyling: false,
});

export default MySwal;