import * as React from "react";

import ReasoningToolPanel from "./Panel";
import AqueductTable from "../../Reactabular/aqueduct_table";
import GenModal from "../../Gen/Modal/component";
import NewEditDocContainer from "../Container";

import { changesDisplayModes } from "../../../helpers/Doc/changes";

interface CaseMapPanelProps {
  userContext: UserContext;
  mappingContext: MappingContext;
  mergedData: MergedData;
};

interface CaseMapPanelState {
  selectedTeachingPoint?: ReusableObject;
  selectedAssessmentQuestion?: AssessmentQuestion;
};

export default class CaseMapPanel extends React.Component<CaseMapPanelProps, CaseMapPanelState> {
  constructor(props: CaseMapPanelProps) {
    super(props);
    this.state = {};
  }

  closeTeachingPointModal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({selectedTeachingPoint: undefined})
  }

  openTeachingPointModal(reusableObject: ReusableObject) {
    event.preventDefault();
    this.setState({selectedTeachingPoint: reusableObject})
  }

  closeAssessmentQuestionModal(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    this.setState({selectedAssessmentQuestion: undefined})
  }

  openAssessmentQuestionModal(assessmentQuestion: AssessmentQuestion) {
    event.preventDefault();
    this.setState({selectedAssessmentQuestion: assessmentQuestion})
  }

  render() {
    let modal
    if (this.state.selectedTeachingPoint) {
      modal = <GenModal open={this.state.selectedTeachingPoint}>
        <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
          <div style={{"fontWeight": "bold", "fontSize": "1.2vw"}}>
            <NewEditDocContainer
              docType="ReusableObject"
              documentId={this.state.selectedTeachingPoint.id}
              versionId={this.state.selectedTeachingPoint.review_variant_id}
              currentUser={{
                id: this.props.userContext.id,
                first_name: this.props.userContext.firstName,
                last_name: this.props.userContext.lastName,
                email: this.props.userContext.email,
              }}
              isAdmin={this.props.userContext.isAdmin}
              permissions={{}}
              isEmbeded
              defaultChangesDisplayMode={changesDisplayModes.HIGHLIGHT_ALL_CHANGES}
            />
          </div>
          <div>
            <button onClick={this.closeTeachingPointModal.bind(this)}>Close</button>
          </div>
        </div>
      </GenModal>
    }

    let assessmentQuestionModal
    if (this.state.selectedAssessmentQuestion) {
      let learningObjective;
      if(this.state.selectedAssessmentQuestion.mapped_learning_objective_edited){
        learningObjective = <label style={{color: 'red'}}>{this.state.selectedAssessmentQuestion["mapped_learning_objective"]}</label>
      }else{
        learningObjective= <>{this.state.selectedAssessmentQuestion["mapped_learning_objective"]}</>
      }
      let teachingPoint;
      if(this.state.selectedAssessmentQuestion.mapped_teaching_point_edited){
        teachingPoint = <label style={{color: 'red'}}>{this.state.selectedAssessmentQuestion["mapped_teaching_point"]}</label>
      }else{
        teachingPoint= <>{this.state.selectedAssessmentQuestion["mapped_teaching_point"]}</>
      }
      let correctAnswer;
      if(this.state.selectedAssessmentQuestion.answer_edited){
        correctAnswer = <label style={{color: 'red'}}>{this.state.selectedAssessmentQuestion["sr_version_correct_answer"]}</label>
      }else{
        correctAnswer= <>{this.state.selectedAssessmentQuestion["sr_version_correct_answer"]}</>
      }

      assessmentQuestionModal = <GenModal open={this.state.selectedAssessmentQuestion} onClick={(e) => e.stopPropagation()}>
        <div className="gen-modal-panel gen-modal-media-menu" style={{marginTop: 90 + 'px'}}>
          <div style={{"fontWeight": "bold", "fontSize": "1.2vw"}}>
            <NewEditDocContainer
              documentId={this.state.selectedAssessmentQuestion.editing_document_id}
              versionId={this.state.selectedAssessmentQuestion.editing_document.scheduled_review_version_id}
              currentUser={{
                id: this.props.userContext.id,
                first_name: this.props.userContext.firstName,
                last_name: this.props.userContext.lastName,
                email: this.props.userContext.email,
              }}
              isAdmin={this.props.userContext.isAdmin}
              permissions={{}}
              isEmbeded
              defaultChangesDisplayMode={changesDisplayModes.HIGHLIGHT_ALL_CHANGES}
            />
          </div>
          <div><h6>Correct Answer: {correctAnswer}</h6></div><br/>
          <div><h6>{`Document: ${this.state.selectedAssessmentQuestion["document_title"]}`}</h6></div><br/>
          <div><h6>{`Scorable: ${this.state.selectedAssessmentQuestion["scorable?"]}`}</h6></div><br/>
          <div><h6>{`Difficulty: ${this.state.selectedAssessmentQuestion["difficulty"]}`}</h6></div><br/>
          <div><h6>{`Discrimination: ${this.state.selectedAssessmentQuestion["discrimination"]}`}</h6></div><br/>
          <div><h6>{`Status: ${this.state.selectedAssessmentQuestion["status"]}`}</h6></div><br/>
          <div><h6>{`Efficiency timing: ${this.state.selectedAssessmentQuestion["status"] ? this.state.selectedAssessmentQuestion.efficiency_breakpoints_seconds.toString() : ""}`}</h6></div><br/>
          <div><h6>Learning Objective: {learningObjective}</h6></div><br/>
          <div><h6>Teaching Point: {teachingPoint}</h6></div><br/>
          <div><h6>{`Operational Difficulty: ${this.state.selectedAssessmentQuestion["difficulty"]}`}</h6></div><br/>
          <div><h6>{`Operational Discrimination: ${this.state.selectedAssessmentQuestion["discrimination"]}`}</h6></div><br/>
          <div><h6>{`Operational Administrations Count: ${this.state.selectedAssessmentQuestion["administrations_count"]}`}</h6></div><br/>
          <br/>
          <div>
            <button onClick={this.closeAssessmentQuestionModal.bind(this)}>Close</button>
          </div>
        </div>
      </GenModal>
    }

    let row_hashes = []
    let row_hash = {}
    let idx = 0
    if(this.props.mappingContext.learningObjectiveReusableObjects && this.props.mappingContext.learningObjectiveReusableObjects.length > 0){
      this.props.mappingContext.learningObjectiveReusableObjects.forEach((learningObjectiveReusableObject) => {
        let loro_data = {
          learning_objective: learningObjectiveReusableObject.learning_objective.name,
          teaching_point: learningObjectiveReusableObject.reusable_object.content_title,
          teaching_point_edited: learningObjectiveReusableObject.reusable_object.reusable_object_edited,
          openTeachingPointModal: this.openTeachingPointModal.bind(this),
          teachingPointObject: learningObjectiveReusableObject.reusable_object,
        }
        if(learningObjectiveReusableObject.questions.length==0){
          idx = idx+1
          row_hash = {
            ...loro_data,
            id: idx,
            assessment_question: "",
            body_system: "",
          }
          row_hashes.push(row_hash)
        } else{
          learningObjectiveReusableObject.questions.forEach((question) => {
            idx = idx+1
            row_hash = {
              ...loro_data,
              id: idx,
              assessment_question: question.case_summary_identifier,
              body_system: question.mapped_system,
              current_use: question.current_use,
              assessmentQuestionObject: question,
              openAssessmentQuestionModal: this.openAssessmentQuestionModal.bind(this),
              isMappedSystemEdited: question.is_mapped_system_edited,
              isAssessmentQuestionEdited: question.question_edited,
              isAssessmentQuestionUsedAvailableForm: question.used_in_available_form,
            }
            row_hashes.push(row_hash)
          })
        }
      })
    }

    let caseMapTable = null
    if(row_hashes.length==0){
      caseMapTable = <h5>There are no mapped learning objectives.</h5>
    }else{
      caseMapTable = <AqueductTable
        table_def="case_map"
        name_prefix="learningObjectiveReusableObjects"
        rows={row_hashes}
      />
    }

    return (<>
      {modal}
      {assessmentQuestionModal}
      <ReasoningToolPanel
        key="CASE MAP"
        title={`Case Content Map: ${this.props.mergedData.title.text.split(":")[0]}`}
        wide={true}
      >
        <p>This case content map displays the Teaching Point(s) that are mapped to each learning objective in 1:1 associations grouped by row.</p>
        {caseMapTable}
      </ReasoningToolPanel>
    </>);
  }
}
