import * as React from 'react';
import { useEffect, useState } from "react";

import ReasoningToolPanel from "./../Panel";
import DetailsTab from "./DetailsTab";
import StatisticsTab from "./StatisticsTab";
import VersionsList from "../../../Admin/Versions/List";
import MySwal from "../../../../ui/MySwal";

import { getAdminAssessmentQuestion, forkAdminAssessmentQuestion } from "../../../../api/sdk.gen";

interface AssessmentQuestionDetailsPanelProps {
  editingContextExtra: EditingContextExtra
};

export default function AssessmentQuestionDetailsPanel(props: AssessmentQuestionDetailsPanelProps) {
  const {
    editingContextExtra
  } = props;

  const [activeTab, setActiveTab] = useState("details");
  const [details, setDetails] = useState(null);

  const questionId = editingContextExtra.assessmentQuestion.id

  const fetchDetails = async () => {
    const resp = await getAdminAssessmentQuestion({ path: { id: questionId.toString() } });
    setDetails(resp.data);
  };

  useEffect(() => {
    fetchDetails();
  }, [questionId]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleDuplicate = async () => {
    const confirmDuplicate = await MySwal.fire({
      title: "Are you sure you want to duplicate this question?",
      showCancelButton: true,
      confirmButtonText: "Duplicate",
    });

    if (confirmDuplicate.isConfirmed) {
      const result = await forkAdminAssessmentQuestion({ path: { id: questionId.toString() } });

      if (result.error) {
        MySwal.fire("Error!", `Error duplicate selected. ${(result as { error: { error: string } }).error.error}`, "error");
      } else {
        MySwal.fire("Duplicate finished!", result.data.message, result.data.success ? "success" : "error");
      }
    }
  }

  if (!details) return <div>Loading...</div>;

  return (<>
    <ReasoningToolPanel
      key="Assessment Question Details"
      title={`Assessment Question Details`}
      wide={true}
    >
      <main style={{ margin: '10px' }}>
        <header style={{ display: 'flex', alignItems: 'center' }}>
          <h2>Show Question {questionId}</h2>
          <div>
            <button style={{ marginLeft: '10px' }} onClick={handleDuplicate}>
              Branch
            </button>
          </div>
        </header>
        <div style={{ padding: '10px 0', fontStyle: 'italic', color: '#ff5251' }}>
          {details.lock_status_msg}
        </div>
        <section className="main-content__body">
          <ul className="nav nav-tabs" id="myTab">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "details" ? "active" : ""}`}
                id="details-tab"
                onClick={() => handleTabChange("details")}
              >
                Details
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "statistics" ? "active" : ""}`}
                id="statistics-tab"
                onClick={() => handleTabChange("statistics")}
              >
                Statistics
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === "versions" ? "active" : ""}`}
                id="versions-tab"
                onClick={() => handleTabChange("versions")}
              >
                Versions
              </a>
            </li>
          </ul>
          <div className="new-tab-content" id="myTabContent">
            <div className={`tab-pane fade ${activeTab === "details" ? "active show" : ""}`} id="details">
              <DetailsTab details={details} />
            </div>
            <div className={`tab-pane fade ${activeTab === "statistics" ? "active show" : ""}`} id="statistics">
              <StatisticsTab questionId={questionId} />
            </div>
            <div className={`tab-pane fade ${activeTab === "versions" ? "active show" : ""}`} id="versions">
              <VersionsList itemId={questionId} itemType="Assessment::Question" itemData={details} />
            </div>
          </div>
        </section>
      </main>
    </ReasoningToolPanel>
  </>);
}
