import * as React from "react";

import * as R from "ramda";

import ahoy from "ahoy.js"

import * as classNames from "classnames";

import { backgroundStyleFromNode } from "../../../helpers/Doc/changes";

import AqButton from "../../Aq/Button";
import AqInfoBubble from "../../Aq/InfoBubble";
import AqSwitch from "../../Aq/Switch";
import AqSmileRating from "../../Aq/SmileRating";

import GenModal from "../../Gen/Modal/component";
import GenLoadingSpinner from "../../Gen/LoadingSpinner/component";

import DocBlockNodes from "../BlockNodes";

import { handleActions } from "../../../helpers/Doc/handleActions";
import { wrapStateAsSetNodeKVPairActions } from "../../../helpers/Doc/documentUserState";

import createAutomatedSummaryStatementSubscription from "../../../channels/automated_summary_statement_channel"
import analytics from "../../../../application/analytics";

interface DocTextInputProps extends DocTextInputType, BlockNodeAll {
  aiSummaryStatementAllowFeedback?: boolean;
  aiSummaryStatementRequestFeedback?: boolean;
  aiSummaryStatementResponseRecieved?: boolean;
  aiSummaryStatementResponseError?: string;
  aiSummaryStatementFeedback?: string;
  aiSummaryStatementUserRating?: number;
  aiSummaryStatementUserComment?: string;
  aiSummaryStatementUserRatingSubmitted?: boolean;
  aiSubmitButtonActions?: Array<DocAction>;
  aiSummaryStatementIdeal?: string;
};

interface DocTextInputState {
  value: string,
  timer?: NodeJS.Timeout,
  aiSummaryStatementLoadingFeedback: boolean,
  tempAiSummaryStatementUserRating: number,
  tempAiSummaryStatementUserComment: string,
  aiSummaryStatementNeedsToAcceptInfo?: boolean,
  aiSummaryStatementLoadingFeedbackStartedAt?: Date,
}

const MAX_SUMMARY_STATEMENT_TIME = 60000

export default class DocTextInput extends React.PureComponent<DocTextInputProps, DocTextInputState> {
  aiAutomatedSummaryStatementSubscription: any; // TODO: Remove any
  aiAutomatedSummaryStatementFeedbackRequested?: boolean;
  aiAutomatedSummaryStatementSubscriptionTimer?: ReturnType<typeof setTimeout>;

  constructor(props: DocTextInputProps) {
    super(props);

    this.state = {
      value: this.props.value,
      timer: undefined,
      aiSummaryStatementLoadingFeedback: false,
      tempAiSummaryStatementUserRating: 0,
      tempAiSummaryStatementUserComment: ""
    };
  }

  componentWillReceiveProps(newProps: DocTextInputProps) {
    if ((newProps.value !== this.props.value) && (newProps.value !== this.state.value)) {
      this.setState({value: newProps.value});
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      this.updateUSCWithTimer();
    }
  }

  handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({value: e.target.value});

    if (this.state.timer) { clearTimeout(this.state.timer); }
    this.setState({timer: setTimeout(this.updateUSCWithTimer.bind(this), 2000)});
  };

  updateUSC() {
    this.props.userContext.addState({
      [this.props.uid]: {value: this.state.value},
    });
  };

  updateUSCWithTimer() {
    this.updateUSC();
    this.setState({timer: undefined});
  };

  loadSummaryStatementFeedback() {
    const {
      aiSummaryStatementIdeal,
      documentContext,
      uid,
      userContext,
    } = this.props;

    this.setState({
      aiSummaryStatementLoadingFeedback: true,
      aiSummaryStatementLoadingFeedbackStartedAt: (new Date()),
      aiSummaryStatementNeedsToAcceptInfo: true,
    })

    this.aiAutomatedSummaryStatementSubscription = createAutomatedSummaryStatementSubscription({
      summaryStatementId: `${userContext.id}::${uid}`,
      onSummaryStatementFeedback: this.onSummaryStatementFeedback.bind(this),
      onConnected: () => {
        console.log("onConnected")

        if (!this.aiAutomatedSummaryStatementFeedbackRequested) {
          this.aiAutomatedSummaryStatementFeedbackRequested = true;

          this.aiAutomatedSummaryStatementSubscription.getFeedback({
            ideal_summary_statement: aiSummaryStatementIdeal,
            user_summary_statement: this.state.value,
          })

          this.aiAutomatedSummaryStatementSubscriptionTimer = setTimeout(() => {
            userContext.addState({
              [uid]: {
                aiSummaryStatementRequestFeedback: true,
                aiSummaryStatementResponseError: "Connection Timeout",
              },
            });
          }, MAX_SUMMARY_STATEMENT_TIME);
        }
      },
      onDisconnected: () => {},
      onRejected: this.handleConnectionRejected.bind(this),
    })

    ahoy.track("AI Summary Statement Feedback Requested", {document_id: documentContext.id});
  }

  handleConnectionClosed() {
    console.log("handleConnectionClosed")
  }

  handleConnectionRejected() {
    console.log("handleConnectionRejected")

    const {
      aiSummaryStatementLoadingFeedback,
    } = this.state

    const {
      aiSummaryStatementResponseError,
      uid,
      userContext,
    } = this.props

    if (aiSummaryStatementLoadingFeedback && !aiSummaryStatementResponseError) {
      userContext.addState({
        [uid]: {
          aiSummaryStatementRequestFeedback: true,
          aiSummaryStatementResponseError: "Connection Rejected",
        },
      });
    }
  }

  onSummaryStatementFeedback(feedback) {
    const {
      uid,
      userContext,
      aiSummaryStatementIdeal,
      aiSubmitButtonActions,
    } = this.props;

    const {
      aiSummaryStatementLoadingFeedbackStartedAt,
    } = this.state;

    if (this.aiAutomatedSummaryStatementSubscriptionTimer) {
      clearTimeout(this.aiAutomatedSummaryStatementSubscriptionTimer)
      this.aiAutomatedSummaryStatementSubscriptionTimer = undefined;
    }

    if (feedback.error) {
      userContext.addState({
        [uid]: {
          aiSummaryStatementRequestFeedback: true,
          aiSummaryStatementResponseError: feedback.error.message,
        },
      });

      return;
    }

    this.setState({aiSummaryStatementLoadingFeedback: false});
    userContext.addState({
      [uid]: {
        aiSummaryStatementRequestFeedback: true,
        aiSummaryStatementResponseRecieved: true,
        aiSummaryStatementResponseError: null,
        aiSummaryStatementResponseTime: (new Date()).getTime() - aiSummaryStatementLoadingFeedbackStartedAt.getTime(),
        aiSummaryStatementFeedback: feedback.ai_response,
        aiSummaryStatementModelId: feedback.ai_prompt_id,
        aiSummaryStatementModelUsageTokens: feedback.usage_tokens,
        aiSummaryStatementIdeal: aiSummaryStatementIdeal,
      },
    });
  }

  render() {
    const {
      boxLines,
      characterLimit,
      classes,
      documentContext,
      editingContext,
      graded,
      inactiveBool,
      uid,
      userContext,
      aiAnswerCommentSection,
      aiSummaryStatementAllowFeedback,
      aiSummaryStatementRequestFeedback=true,
      aiSummaryStatementResponseRecieved,
      aiSummaryStatementResponseError,
      aiSummaryStatementFeedback,
      aiSummaryStatementUserRating,
      aiSummaryStatementUserComment,
      aiSummaryStatementUserRatingSubmitted,
      aiSubmitButtonActions,
    } = this.props;

    const {
      aiSummaryStatementLoadingFeedback,
      aiSummaryStatementNeedsToAcceptInfo,
      tempAiSummaryStatementUserRating,
      tempAiSummaryStatementUserComment,
    } = this.state;

    const inactive = inactiveBool || aiSummaryStatementLoadingFeedback || aiSummaryStatementResponseRecieved;

    let studentNotice;
    if (classes?.includes("summary_statement")) {
      studentNotice = <p className="graded-feedback">Your response will be recorded in your Student Report and may also be viewed by faculty.</p>;
    } else if(graded) {
      studentNotice = <p className="graded-feedback">The suggested answer is shown below.</p>;
    }

    const textInputArea = (
      <>
        <textarea
          cols={40}
          rows={boxLines}
          maxLength={characterLimit}
          disabled={inactive}
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
        />
        {!inactive && <p className="letter-count">Letter Count: {(this.state.value || "").length}/{characterLimit}</p>}
      </>
    )

    let renderedInput = (
      <div>
        {studentNotice}
        {textInputArea}
      </div>
    )

    let aiSubmitButton
    let aiSwitch
    if (aiSummaryStatementAllowFeedback) {
      if (!(inactive || aiSummaryStatementLoadingFeedback || aiSummaryStatementResponseRecieved)) {
        aiSwitch = (
          <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
            <AqSwitch
              checked={aiSummaryStatementRequestFeedback}
              onChange={() => {userContext.addState({ [uid]: {aiSummaryStatementRequestFeedback: !aiSummaryStatementRequestFeedback} })}}
              disabled={inactive || !!aiSummaryStatementResponseError}
            />
            <span style={{margin: '0 10px'}}>
              Receive AI-generated feedback on my summary statement
            </span>
            <div>
              <AqInfoBubble
                infoText={
                  userContext?.groupRoleDefinition?.role_definition.name === "Student" ?
                  "This is an experimental new feature. AI-generated feedback will not be shared with faculty." :
                  "Students have the option of receiving AI-generated feedback on their summary statements for their own study use, but they cannot edit their statements after their initial response. Please feel free to try out this new feature!"
                }
                onMouseEnter={() => {ahoy.track("AI Summary Statement i-bubble Hovered Over", {document_id: documentContext.id});}}
                />
            </div>
          </div>
        )

        aiSubmitButton = (
          <div style={{marginBottom: '10px'}}>
            <AqButton
              onClick={() => {
                if (aiSummaryStatementRequestFeedback) {
                  this.loadSummaryStatementFeedback();
                } else {
                  handleActions(aiSubmitButtonActions, this.props);

                  if (!aiSummaryStatementResponseError) {
                    ahoy.track("AI Summary Statement Feedback Opt Out", {document_id: documentContext.id});
                  }
                }
              }}
              disabled={R.isEmpty(this.state.value)}
            >
              SUBMIT
            </AqButton>
          </div>
        )

        renderedInput = (
          <div>
            {studentNotice}
            {textInputArea}
            {aiSwitch}
            {aiSubmitButton}
          </div>
        )
      } else if (aiSummaryStatementRequestFeedback && (aiSummaryStatementLoadingFeedback || aiSummaryStatementResponseRecieved)) {
        let userRating, answerCommentSection;
        if (aiSummaryStatementResponseRecieved) {
          answerCommentSection = (
            <div style={{marginTop: "15px"}}>
              <DocBlockNodes
                {...{...this.props, inactiveBool: false}}
                content={[aiAnswerCommentSection]}
                contentKey="content"
                independentlyEditable
                simple
                shouldHaveAddNodes={editingContext.isEditing}
              />
            </div>
          )

          userRating = (
            <div>
              <hr/>
              <div style={{marginBottom: '10px'}}>
                <p>My reaction to this feedback is best expressed as: <span style={{color: 'red'}}>*</span></p>
                <AqSmileRating
                  value={aiSummaryStatementUserRatingSubmitted ? aiSummaryStatementUserRating : tempAiSummaryStatementUserRating}
                  disabled={aiSummaryStatementUserRatingSubmitted}
                  onChange={(newRating) => {
                    this.setState({tempAiSummaryStatementUserRating: newRating});
                  }}
                />
              </div>
              <div style={{marginBottom: '10px'}}>
                <p>How could this feedback process be improved:</p>
                <textarea
                  value={aiSummaryStatementUserRatingSubmitted ? aiSummaryStatementUserComment : tempAiSummaryStatementUserComment}
                  disabled={aiSummaryStatementUserRatingSubmitted}
                  onChange={(e) => {this.setState({tempAiSummaryStatementUserComment: e.target.value})}}
                />
              </div>
              <div style={{marginBottom: '10px'}}>
                <AqButton
                  onClick={() => {
                    const extraActions = wrapStateAsSetNodeKVPairActions({ [uid]: {
                      aiSummaryStatementUserRating: tempAiSummaryStatementUserRating,
                      aiSummaryStatementUserComment: tempAiSummaryStatementUserComment,
                      aiSummaryStatementUserRatingSubmitted: true,
                    }});
                    handleActions(aiSubmitButtonActions.concat(extraActions), this.props);
                  }}
                  disabled={tempAiSummaryStatementUserRating < 1 || aiSummaryStatementUserRatingSubmitted}
                >
                  SUBMIT
                </AqButton>
              </div>
            </div>
          )
        }

        let summaryStatementModal;
        if (aiSummaryStatementResponseError) {
          summaryStatementModal = (
            <GenModal open>
              <div className="gen-modal-panel" style={{}}>
                <p>Oops! This feature is experiencing difficulty. Sorry for the hassle. You can still submit your summary statement and continue working through the case.</p>

                <div style={{display: 'flex', gap: '20px', justifyContent: 'center', width: '100%'}}>
                  <AqButton
                    onClick={() => {
                      userContext.addState({
                        [uid]: {
                          aiSummaryStatementRequestFeedback: false,
                        },
                      })
                      this.setState({aiSummaryStatementLoadingFeedback: false});
                    }}
                  >
                    CONTINUE
                  </AqButton>
                </div>
              </div>
            </GenModal>
          )
        } else if(aiSummaryStatementNeedsToAcceptInfo || aiSummaryStatementLoadingFeedback) {
          summaryStatementModal = (
            <GenModal open>
              <div className="gen-modal-panel" style={{}}>
                <p><em>This feedback is being generated by using artificial intelligence to compare your summary statement to an ideal summary statement and criteria for effective summary statements. The ideal summary statements and the criteria were created by case authors, which include clerkship directors, program directors, clinical educators, and other health professions educators. The artificial intelligence model used to provide feedback is being fine-tuned using expert input from clinicians.</em></p>

                <div style={{display: 'flex', gap: '20px', justifyContent: 'center', width: '100%'}}>
                  {
                    aiSummaryStatementNeedsToAcceptInfo ? (
                      <AqButton
                        onClick={() => {
                          this.setState({aiSummaryStatementNeedsToAcceptInfo: false});
                          analytics.track("use_ai_preceptor_for_summary_statement_feedback", { case: documentContext.title, source: 'Case', page: window.location.href });
                        }}
                      >
                        OKAY
                      </AqButton>
                    ) : (
                      <GenLoadingSpinner/>
                    )
                  }
                </div>
              </div>
            </GenModal>
          )
        }

        renderedInput = (
          <div>
            <div style={{display: 'flex', gap: '10px'}}>
              <div style={{flex: "0 1 50%"}}>
                <h5>Your Summary Statement</h5>
                {studentNotice}
              </div>
              <div style={{flex: "0 1 50%"}}>
                <h5 style={{paddingBottom: "10px"}}>AI-Generated Feedback</h5>
              </div>
            </div>
            <div style={{display: 'flex', gap: '10px'}}>
              <div style={{flex: "0 1 50%"}}>
                {textInputArea}
              </div>
              <div style={{flex: "0 1 50%", backgroundColor: "white", padding: "10px", marginBottom: "10px"}}>
                {aiSummaryStatementLoadingFeedback || !aiSummaryStatementFeedback ? <GenLoadingSpinner/> : R.trim(aiSummaryStatementFeedback).split(/\n+/).map((feedbackParagraph, i) => <p key={i}>{feedbackParagraph}</p>)}
              </div>
            </div>
            {answerCommentSection}
            <div style={{display: 'flex', gap: '10px', marginTop: "10px"}}>
              <div style={{flex: "0 1 50%"}}>
                {userRating}
              </div>
              <div style={{flex: "0 1 50%"}}>
              </div>
            </div>
            {summaryStatementModal}
          </div>
        )
      } else if (!aiSummaryStatementRequestFeedback && inactive) {
        renderedInput = (
          <div>
            {studentNotice}
            {textInputArea}
            <div style={{marginTop: "15px"}}>
              <DocBlockNodes
                {...{...this.props, inactiveBool: false}}
                content={[aiAnswerCommentSection]}
                contentKey="content"
                independentlyEditable
                simple
                shouldHaveAddNodes={editingContext.isEditing}
              />
            </div>
          </div>
        )
      }
    }

    return (
      <div
        id={uid}
        data-is-node
        data-uid={uid}
        className={classNames("doc-text-input", uid, {inactive: inactive})}
        style={backgroundStyleFromNode(this.props)}
        contentEditable={false}
      >
        {renderedInput}
      </div>
    );
  }
}
