export const formatDate = (
  date: Date | string | number, 
  format: string = "MM-DD-YYYY"
): string => {
  let parsedDate: Date;

  if (date instanceof Date) {
    parsedDate = date;
  } else {
    parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      throw new Error("Invalid date");
    }
  }

  const map: Record<string, string> = {
    YYYY: parsedDate.getFullYear().toString(),
    MM: String(parsedDate.getMonth() + 1).padStart(2, "0"),
    DD: String(parsedDate.getDate()).padStart(2, "0"),
    HH: String(parsedDate.getHours()).padStart(2, "0"),
    mm: String(parsedDate.getMinutes()).padStart(2, "0"),
    ss: String(parsedDate.getSeconds()).padStart(2, "0"),
  };

  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, (match) => map[match]);
}